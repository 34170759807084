import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {Chart as ChartJS, ChartType, registerables} from 'chart.js/auto';

import {selectRetailValue} from '../../../store/slices/retailValue/selectors';
import SellThroughInfo from '../SellTroughInfo/SellThroughInfo';

import WeatherInfo from '../WeatherInfo/WeatherInfo';
import RevenueInfo from '../RevenueInfo/RevenueInfo';

import styles from './Charts.module.scss';

interface IProps {
    chartType: ChartType;
    setChartType: (arg: ChartType) => void;
}

const Charts: FC<IProps> = ({chartType, setChartType}) => {
    ChartJS.register(...registerables);

    const retailerValue = useSelector(selectRetailValue);

    return (
        <div className={styles.charts}>
            <RevenueInfo />
            <div className={styles.center}>
                <div className={styles.info}>
                    <div className={styles['info-card']}>
                        <p>Retail Value</p>
                        <div className={styles.value}>{`${retailerValue}k`}</div>
                    </div>
                </div>
                <SellThroughInfo chartType={chartType} setChartType={setChartType} />
            </div>
            <div className={styles.right}>
                <WeatherInfo />
            </div>
        </div>
    );
};

export default React.memo(Charts);
