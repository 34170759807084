export const slicesNames = {
    logIn: 'logIn',
    revenue: 'revenue',
    orders: 'orders',
    forecast: 'forecast',
    filters: 'filters',
    sellThrough: 'sellThrough',
    retailValues: 'retailValues',
};

export const statusesTypes = {
    loading: 'loading',
    finished: 'finished',
    error: 'error',
};
