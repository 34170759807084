import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';
import {fetchSellsThrough} from './thunks';

const initialState = {
    storeId: 12345,
    sellThrough: {
        '2022': 75.5,
        '2023': 82.2,
        '2024': 80.3,
    },
};

export const sellsThroughSlice = createGenericSlice({
    name: slicesNames.sellThrough,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSellsThrough.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = payload.data;
            }
        });
    },
});
export const {resetSlice} = sellsThroughSlice.actions;
