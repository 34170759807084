// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RevenueInfo_revenue__iJMxy {
  width: 20%;
  height: 100%;
  border: 1px solid black;
  border-radius: 4px;
}
.RevenueInfo_revenue__iJMxy .RevenueInfo_title__SzbV8 {
  font-weight: 400;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateOrders/RevenueInfo/RevenueInfo.module.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;AADJ;AAGI;EACI,gBAAA;EACA,kBAAA;AADR","sourcesContent":["@import '../../../assets/scss/variables';\n\n.revenue {\n    width: 20%;\n    height: 100%;\n    border: 1px solid black;\n    border-radius: 4px;\n\n    .title {\n        font-weight: 400;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"revenue": `RevenueInfo_revenue__iJMxy`,
	"title": `RevenueInfo_title__SzbV8`
};
export default ___CSS_LOADER_EXPORT___;
