import React, {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {LINKS} from '../../../globalConstants';

interface PropType {
    component: React.FC;
}

const ProtectedRoute: FC<PropType> = ({component: Component}) => {
    const isLogged = localStorage.getItem('auth-token');
    if (isLogged) return <Component />;
    return <Navigate to={LINKS.LANDING} />;
};

export default ProtectedRoute;
