import React, {FC, useState} from 'react';
import {Button} from 'primereact/button';

import {IOrdersParams, ISetOrdersParams} from '../types';
import {IMappedOrdersItem} from '../../../api/orders/types';

import CreateOrdersList from '../List/CreateOrdersList';
import Charts from '../Charts/Charts';
import {ChartType} from 'chart.js/auto';

import styles from './CreateOrdersMain.module.scss';
import Navigation from '../../Navigation/Navigation';

interface IProps {
    params: IOrdersParams;
    setParams: ISetOrdersParams;
    isLoading: boolean;
    selectedOrder: IMappedOrdersItem;
    setSelectedOrder: (arg: IMappedOrdersItem) => void;
    getOrders: () => Promise<void>;
}

const CreateOrdersMain: FC<IProps> = ({params, setParams, isLoading, selectedOrder, setSelectedOrder, getOrders}) => {
    const [chartType, setChartType] = useState<ChartType>('line');
    const breadCrumbItems = [{label: 'Create Orders'}];
    return (
        <div className={styles['create-order-main']}>
            <Navigation items={breadCrumbItems} showBreadcrumbs={true} />
            <div className={styles['content-wrapper']}>
                <Button label="User Settings" raised className={styles['user-settings']} />
                <Charts chartType={chartType} setChartType={setChartType} />
                <CreateOrdersList
                    params={params}
                    setParams={setParams}
                    isLoading={isLoading}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                    getOrders={getOrders}
                />
            </div>
        </div>
    );
};

export default CreateOrdersMain;
