import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Password} from 'primereact/password';
import {ProgressSpinner} from 'primereact/progressspinner';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import classNames from 'classnames';

import {setLogIn} from '../../../store/slices/logIn/logInSlice';
import {LINKS} from '../../../globalConstants';
import {changePasswordFormSchema} from './changePasswordValidationForm';
import {useAppDispatch} from '../../../hooks/redux';

import styles from './ChangePassword.module.scss';

const ChangePassword = () => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        watch,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(changePasswordFormSchema),
    });

    const password = watch('password', '');

    const submitHandler = (data) => {
        dispatch(setLogIn({isLogged: true}));
        navigate(LINKS.LANDING);
    };

    const onSubmit = (data) => {
        submitHandler(data);
    };

    return (
        <div className={styles['change-password']}>
            {isLoading && (
                <div className={styles.spinner}>
                    <ProgressSpinner />
                </div>
            )}
            <div className={styles['login-box']}>
                <div className={styles['login-header']}>
                    <div className={styles['header-text']}>Change Your Password</div>
                </div>
                <div className={styles['info']}>Enter a new password below to change your password</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles['form-group']}>
                        <Controller
                            name="password"
                            control={control}
                            render={({field}) => (
                                <Password
                                    type="password"
                                    {...field}
                                    className="password-field"
                                    feedback={false}
                                    toggleMask
                                />
                            )}
                        />
                        {errors.password?.message && <span className="error-message">{errors.password?.message}</span>}
                    </div>
                    <div className={styles['form-group']}>
                        <Controller
                            name="confirmPassword"
                            control={control}
                            render={({field}) => (
                                <Password
                                    type="password"
                                    {...field}
                                    className="password-field"
                                    feedback={false}
                                    toggleMask
                                />
                            )}
                        />
                        {errors.confirmPassword?.message && (
                            <span className="error-message">{errors.confirmPassword?.message}</span>
                        )}
                    </div>
                    <div className={styles.tips}>
                        <ul>
                            <li className={classNames(password.length >= 8 && styles.green)}>At least 8 characters</li>
                            <li className={classNames(/[a-z]/.test(password) && styles.green)}>
                                At least one lowercase letter
                            </li>
                            <li className={classNames(/[A-Z]/.test(password) && styles.green)}>
                                At least one uppercase letter
                            </li>
                            <li className={classNames(/[0-9]/.test(password) && styles.green)}>At least one number</li>
                        </ul>
                    </div>
                    <button type="submit" className={styles['submit-button']}>
                        RESET PASSWORD
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
