import axios from 'axios';
import {BASE_URL, responseTypes} from '../globalConstants';

export const sendRequest = async (
    url: string,
    method: string,
    data: object,
    responseType: responseTypes = responseTypes.JSON,
) => {
    return axios({
        method: method,
        url: `${BASE_URL}/${url}`,
        data: data,
        responseType: responseType,
    });
};
