import React from 'react';

import Navigation from '../Navigation/Navigation';

import styles from './LandingPage.module.scss';

const LandingPage = () => {
    return (
        <div className={styles['landing-page']}>
            <Navigation items={null} showBreadcrumbs={false} />
            <h2>Landing Page</h2>
        </div>
    );
};

export default LandingPage;
