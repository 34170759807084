import React from 'react';
import {confirmDialog} from 'primereact/confirmdialog';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {InputMask} from 'primereact/inputmask';
import {Button} from 'primereact/button';
import {Divider} from 'primereact/divider';
import {InputText} from 'primereact/inputtext';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import LeftSideBar from '../Common/LeftSideBar/LeftSideBar';
import Navigation from '../Navigation/Navigation';

import {userProfileFormSchema} from './userProfileFormValidation';
import {IUserInfo} from '../../globalTypes';
import {initialValues} from '../CreateOrders/constants';

import PromptIfDirty from '../Common/PromptIfDirty/PromptIfDirty';

import styles from './UserProfile.module.scss';

const UserProfile = () => {
    const breadCrumbItems = [{label: 'User Profile'}];

    const {register, handleSubmit, formState, reset, getValues} = useForm({
        mode: 'onBlur',
        defaultValues: initialValues,
        resolver: yupResolver(userProfileFormSchema),
    });

    const submitHandler = (data: IUserInfo) => {
        // eslint-disable-next-line no-console
        console.log(data);
    };

    const onSubmit = (data: IUserInfo) => {
        submitHandler(data);
    };

    const showConfirm = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                reset();
            },
            reject: () => {
                // eslint-disable-next-line no-console
                console.log('Rejected!');
            },
        });
    };

    return (
        <div className={styles['user-profile']}>
            <LeftSideBar />
            <div className={styles['content']}>
                <Navigation items={breadCrumbItems} showBreadcrumbs={true} />
                <PromptIfDirty dirty={formState.isDirty} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>User Information</h3>
                    <Divider />
                    <div className="p-fluid">
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="firstName" className="col-12 lg:col-2 font-bold">
                                First Name
                            </label>
                            <div className="col-12 lg:col-4">
                                <InputText
                                    id="firstName"
                                    type="text"
                                    {...register('firstName')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.firstName && 'input-error-border',
                                    )}
                                />
                                {formState.errors.firstName?.message && (
                                    <span className="error-message">{formState.errors.firstName?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="lastName" className="col-12 lg:col-2 font-bold">
                                Last Name
                            </label>
                            <div className="col-12 lg:col-4">
                                <InputText
                                    id="lastName"
                                    type="text"
                                    {...register('lastName')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.lastName && 'input-error-border',
                                    )}
                                />
                                {formState.errors.lastName?.message && (
                                    <span className="error-message">{formState.errors.lastName?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="email" className="col-12 lg:col-2 font-bold">
                                Email
                            </label>
                            <div className="col-12 lg:col-4">
                                <InputText
                                    id="email"
                                    type="email"
                                    {...register('email')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.email && 'input-error-border',
                                    )}
                                />
                                {formState.errors.email?.message && (
                                    <span className="error-message">{formState.errors.email?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="phoneNumber" className="col-12 lg:col-2 font-bold">
                                Phone Number
                            </label>
                            <div className="col-12 lg:col-4">
                                <InputMask
                                    id="phoneNumber"
                                    mask="(999) 999-9999"
                                    placeholder="(999) 999-9999"
                                    {...register('phoneNumber')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.email && 'input-error-border',
                                    )}
                                />
                                {formState.errors.phoneNumber?.message && (
                                    <span className="error-message">{formState.errors.phoneNumber?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="companyName" className="col-12 lg:col-2 font-bold">
                                Contract Number
                            </label>
                            <div className="col-12 lg:col-4">
                                <InputText
                                    id="contractNumber"
                                    type="text"
                                    {...register('contractNumber')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.contractNumber && 'input-error-border',
                                    )}
                                />
                                {formState.errors.contractNumber?.message && (
                                    <span className="error-message">{formState.errors.contractNumber?.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="grid col-6 justify-content-end gap-2 pr-2"
                        style={{marginLeft: '0', padding: '30px 0 10px'}}>
                        <Button
                            className={styles.continue}
                            label="Save"
                            disabled={!formState.isDirty || !formState.isValid}
                            type="submit"
                            icon="pi pi-check"
                        />
                        <Button
                            severity="secondary"
                            label="Cancel"
                            disabled={!formState.isDirty}
                            type="button"
                            icon="pi pi-times"
                            onClick={showConfirm}
                        />
                        <ConfirmDialog />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserProfile;
