import createGenericSlice, {IGenericState} from '../createGenericSlice';
import {slicesNames} from '../constants';
import {fetchOrders} from './thunks';
import {IMappedOrdersItem, IOrdersItem, IOrdersState} from '../../../api/orders/types';
import {current, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    orders: [
        {
            id: 1,
            storeName: '',
            city: '',
            state: '',
            ship_zone: '',
            program: '',
            category: '',
            product_code: '',
            item_description: '',
            inventory: {
                on_hand: 0,
                days: 0,
            },
            required: {
                qty: 0,
                shelves: 0,
            },
            on_order: 0,
            availability: {
                qty: 0,
                updated_qty: 0,
                updated_shelves: 0,
            },
            forecast: {
                monday: {
                    date: '',
                    qty: 0,
                    shelves: 0,
                    carts: 0,
                },
            },
        },
    ],
    total: 0,
};

const reducers = {
    updateOrders: (state, updatedOrders: PayloadAction<Partial<IOrdersItem[]>>) => {
        state.data.orders = updatedOrders.payload;
    },
};

export const ordersSlice = createGenericSlice({
    name: slicesNames.orders,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: reducers,
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = payload.data;
            }
        });
    },
});
export const {updateOrders} = ordersSlice.actions;
