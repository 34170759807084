// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApiConfiguration_api-configuration__W0F9m {
  display: flex;
  width: 100%;
  height: 100%;
}
.ApiConfiguration_api-configuration__W0F9m .ApiConfiguration_content__EQj6x {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
.ApiConfiguration_api-configuration__W0F9m .ApiConfiguration_content__EQj6x .ApiConfiguration_form__GhZtt {
  flex: 1 1;
  margin: 1rem;
  padding: 1.25rem;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}
.ApiConfiguration_api-configuration__W0F9m .ApiConfiguration_content__EQj6x .ApiConfiguration_form__GhZtt h3 {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiConfiguration/ApiConfiguration.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,SAAA;EACA,sBAAA;AACR;AACQ;EACI,SAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AACZ;AACY;EACI,SAAA;AAChB","sourcesContent":[".api-configuration {\n    display: flex;\n    width: 100%;\n    height: 100%;\n\n    .content {\n        display: flex;\n        flex: 1 1;\n        flex-direction: column;\n\n        .form {\n            flex: 1 1;\n            margin: 1rem;\n            padding: 1.25rem;\n            overflow-y: auto;\n            background: #fff;\n            border: 1px solid #d3d3d3;\n            border-radius: 4px;\n\n            h3 {\n                margin: 0;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"api-configuration": `ApiConfiguration_api-configuration__W0F9m`,
	"content": `ApiConfiguration_content__EQj6x`,
	"form": `ApiConfiguration_form__GhZtt`
};
export default ___CSS_LOADER_EXPORT___;
