// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PromptIfDirty_prompt__RQCFR {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  transform: translateX(-50%);
}
.PromptIfDirty_prompt__RQCFR h4 {
  margin: 0;
}
.PromptIfDirty_prompt__RQCFR p {
  font-size: 14px;
}
.PromptIfDirty_prompt__RQCFR .PromptIfDirty_prompt-buttons__jjmgB {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/PromptIfDirty/PromptIfDirty.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,2BAAA;AACJ;AACI;EACI,SAAA;AACR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,aAAA;EACA,SAAA;EACA,yBAAA;AADR","sourcesContent":[".prompt {\n    position: fixed;\n    top: 0;\n    left: 50%;\n    z-index: 1000;\n    display: flex;\n    flex-direction: column;\n    padding: 20px 40px;\n    background-color: white;\n    border: 1px solid #ccc;\n    border-radius: 20px;\n    transform: translateX(-50%);\n\n    h4 {\n        margin: 0;\n    }\n\n    p {\n        font-size: 14px;\n    }\n\n    .prompt-buttons {\n        display: flex;\n        gap: 10px;\n        justify-content: flex-end;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prompt": `PromptIfDirty_prompt__RQCFR`,
	"prompt-buttons": `PromptIfDirty_prompt-buttons__jjmgB`
};
export default ___CSS_LOADER_EXPORT___;
