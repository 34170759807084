import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';
import {fetchRevenue} from './thunks';

const initialState = [
    {
        orderYear: '2022',
        orderLineAmount: 150000,
        id: '1',
    },
    {
        orderYear: '2023',
        orderLineAmount: 200000,
        id: '2',
    },
    {
        orderYear: '2024',
        orderLineAmount: 130000,
        id: '3',
    },
];

export const revenueSlice = createGenericSlice({
    name: slicesNames.revenue,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRevenue.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = payload.data;
            }
        });
    },
});
export const {resetSlice} = revenueSlice.actions;
