import {sendRequest, urls} from '../../../src/apiRequestService';
import {getPrimeToMockApi, requestMethod} from '../../globalConstants';
import {IData} from '../../components/CreateOrders/types';
import {getFilteredData} from '../../components/CreateOrders/utils';

//ToDo rebuild after I get real bak end
export const getOrders = async (data: IData) => {
    const newUrl = `${urls.ORDERS}?page=${getPrimeToMockApi(data.params.navigation.page)}&limit=${data.params.navigation.page.size}`;
    const res = await sendRequest(newUrl, requestMethod.GET, data);

    if (!data.filters || Object.values(data.filters).every((o) => o === null)) {
        return {
            ...res,
            data: {
                orders: res.data,
                total: 21,
            },
        };
    } else {
        const filteredData = getFilteredData(data.filters, res.data);

        return {
            ...res,
            data: filteredData,
        };
    }
};
