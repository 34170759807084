export const weatherState = {
    SUN: 'sun',
    SEMI_SUN: 'semi-sun',
    RAIN: 'rain',
    STORM: 'storm',
};

export const orders = [
    {
        id: 1,
        storeName: 'WM0001',
        city: 'BOTHELL',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748123',
        item_description: '4PK Petunia Pink',
        inventory: {
            on_hand: 22,
            days: 21,
        },
        required: {
            qty: 2,
            shelves: 0,
        },
        on_order: 10,
        availability: {
            qty: 2400,
            updated_qty: 1640,
            updated_shelves: 53,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 2,
        storeName: 'WM0002',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '31238185',
        item_description: '4PK Marigold Yellow',
        inventory: {
            on_hand: 22,
            days: 7,
        },
        required: {
            qty: 2,
            shelves: 1,
        },
        on_order: 11,
        availability: {
            qty: 2500,
            updated_qty: 1610,
            updated_shelves: 23,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 3,
                carts: 0.4,
            },
        },
    },
    {
        id: 3,
        storeName: 'CM0001',
        city: 'SACRAMENTO',
        state: 'CA',
        ship_zone: 'GROUP 4',
        program: 'PROG123',
        category: 'CAT001',
        product_code: '37748000',
        item_description: 'Petunia Pink',
        inventory: {
            on_hand: 12,
            days: 8,
        },
        required: {
            qty: 4,
            shelves: 1,
        },
        on_order: 12,
        availability: {
            qty: 1400,
            updated_qty: 1240,
            updated_shelves: 33,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 60,
                shelves: 5,
                carts: 0.4,
            },
        },
    },
    {
        id: 4,
        storeName: 'WM0001',
        city: 'BOTHELL',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'PROG123',
        category: 'CAT001',
        product_code: '37748123',
        item_description: 'Petunia Pink',
        inventory: {
            on_hand: 20,
            days: 21,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 13,
        availability: {
            qty: 2150,
            updated_qty: 1140,
            updated_shelves: 43,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 90,
                shelves: 3,
                carts: 0.2,
            },
        },
    },
    {
        id: 5,
        storeName: 'WM0001',
        city: 'BOTHELL',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748123',
        item_description: '4PK Petunia Pink',
        inventory: {
            on_hand: 21,
            days: 21,
        },
        required: {
            qty: 3,
            shelves: 1,
        },
        on_order: 14,
        availability: {
            qty: 2220,
            updated_qty: 1330,
            updated_shelves: 51,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 75,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 6,
        storeName: 'WM0001',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'PROG456',
        category: 'CAT002',
        product_code: '31238185',
        item_description: 'Marigold Yellow',
        inventory: {
            on_hand: 32,
            days: 23,
        },
        required: {
            qty: 2,
            shelves: 0,
        },
        on_order: 15,
        availability: {
            qty: 2880,
            updated_qty: 1770,
            updated_shelves: 34,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 7,
        storeName: 'WM0002',
        city: 'KIRKLAND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748123',
        item_description: '4PK Petunia Pink',
        inventory: {
            on_hand: 23,
            days: 23,
        },
        required: {
            qty: 2,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 2400,
            updated_qty: 1640,
            updated_shelves: 53,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 8,
        storeName: 'AM0001',
        city: 'ANCHORAGE',
        state: 'AK',
        ship_zone: 'GROUP 3',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '31238333',
        item_description: '4PK Marigold Yellow',
        inventory: {
            on_hand: 22,
            days: 22,
        },
        required: {
            qty: 2,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1111,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 9,
        storeName: 'WM0002',
        city: 'KIRKLAND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 22,
            days: 22,
        },
        required: {
            qty: 2,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 2400,
            updated_qty: 1640,
            updated_shelves: 53,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 10,
        storeName: 'WM0001',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'PROG123',
        category: 'CAT003',
        product_code: '37748185',
        item_description: 'Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 11,
        storeName: 'WM0002',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 12,
        storeName: 'WM0001',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 13,
        storeName: 'WM0001',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'PROG789',
        category: 'CAT004',
        product_code: '37748185',
        item_description: 'Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 14,
        storeName: 'WM0002',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 15,
        storeName: 'WM0002',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 16,
        storeName: 'TM0001',
        city: 'HOUSTON',
        state: 'TX',
        ship_zone: 'GROUP 2',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 17,
        storeName: 'WM0001',
        city: 'BOTHELL',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'PROG789',
        category: 'CAR002',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 18,
        storeName: 'WM0001',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'PROG456',
        category: 'CAT003',
        product_code: '37748185',
        item_description: 'Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 19,
        storeName: 'WM0001',
        city: 'KIRKLAND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 20,
        storeName: 'WM0001',
        city: 'REDMOND',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
    {
        id: 21,
        storeName: 'WM0001',
        city: 'BOTHELL',
        state: 'WA',
        ship_zone: 'GROUP 1',
        program: 'ANN PACKS',
        category: '4PK ANNUAL',
        product_code: '37748185',
        item_description: '4PK Petunia White',
        inventory: {
            on_hand: 12,
            days: 12,
        },
        required: {
            qty: 3,
            shelves: 0,
        },
        on_order: 0,
        availability: {
            qty: 1110,
            updated_qty: 1110,
            updated_shelves: 11,
        },
        forecast: {
            monday: {
                date: '05/06/2024',
                qty: 80,
                shelves: 2,
                carts: 0.3,
            },
        },
    },
];

export const chartOptions = {
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: true,
            text: '   K',
            position: 'top',
            align: 'start',
        },
        legend: {
            display: false,
            position: 'bottom',
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function (value: string) {
                    return '$' + value;
                },
            },
        },
    },
};
export const sellChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: true,
        },
        legend: {
            display: false,
            labels: {
                boxWidth: 10,
                padding: 10,
            },
        },
    },
    scales: {
        y: {
            display: false,
            ticks: {
                display: false,
            },
            grid: {
                display: false,
            },
        },
        x: {
            display: true,
            ticks: {
                display: true,
            },
            grid: {
                display: false,
            },
        },
    },
};

export const initialParams = {
    query: {},
    navigation: {
        sort: {
            key: 'name',
            order: 'ASC',
        },
        page: {
            from: 0,
            size: 10,
        },
    },
};

export const weather = [
    {
        zipcode: 'REDMOND',
        city: 'Redmond',
        state: 'WA',
        years: [
            {
                year: '2024',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 70,
                        precipitation: 20,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 10,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 85,
                        precipitation: 5,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 100,
                        precipitation: 0,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.STORM,
                        temperature: 40,
                        precipitation: 100,
                    },
                ],
            },
            {
                year: '2023',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 60,
                        precipitation: 10,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 10,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 75,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 60,
                        precipitation: 20,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.RAIN,
                        temperature: 50,
                        precipitation: 90,
                    },
                ],
            },
            {
                year: '2022',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 75,
                        precipitation: 15,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 10,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 20,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.RAIN,
                        temperature: 90,
                        precipitation: 20,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                ],
            },
        ],
    },
    {
        zipcode: 'KIRKLAND',
        city: 'Kirkland',
        state: 'WA',
        years: [
            {
                year: '2024',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 10,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 75,
                        precipitation: 15,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 0,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 60,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 50,
                        precipitation: 70,
                    },
                ],
            },
            {
                year: '2023',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                ],
            },
            {
                year: '2022',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 70,
                        precipitation: 20,
                    },
                ],
            },
        ],
    },
    {
        zipcode: 'BOTHELL',
        city: 'Bothell',
        state: 'WA',
        years: [
            {
                year: '2024',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.STORM,
                        temperature: 30,
                        precipitation: 100,
                    },

                    {
                        day: 'Thursday',
                        weather: 'rain',
                        temperature: 40,
                        precipitation: 90,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.RAIN,
                        temperature: 35,
                        precipitation: 90,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 50,
                        precipitation: 30,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 50,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 25,
                    },
                ],
            },
            {
                year: '2023',
                days: [
                    {
                        day: 'Wednesday',
                        weather: 'sun',
                        temperature: 90,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 20,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 60,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 60,
                        precipitation: 20,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 55,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 55,
                        precipitation: 50,
                    },
                ],
            },
            {
                year: '2022',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.STORM,
                        temperature: 30,
                        precipitation: 100,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.STORM,
                        temperature: 35,
                        precipitation: 100,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.RAIN,
                        temperature: 45,
                        precipitation: 95,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.RAIN,
                        temperature: 50,
                        precipitation: 90,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.RAIN,
                        temperature: 50,
                        precipitation: 80,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 55,
                        precipitation: 50,
                    },
                ],
            },
        ],
    },
    {
        zipcode: 'SACRAMENTO',
        city: 'Sacramento',
        state: 'CA',
        years: [
            {
                year: '2024',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 100,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 85,
                        precipitation: 5,
                    },
                ],
            },
            {
                year: '2023',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 20,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 90,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 60,
                        precipitation: 10,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 95,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 90,
                        precipitation: 50,
                    },
                ],
            },
            {
                year: '2022',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 80,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.RAIN,
                        temperature: 85,
                        precipitation: 95,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 80,
                        precipitation: 10,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 85,
                        precipitation: 0,
                    },
                ],
            },
        ],
    },
    {
        zipcode: 'HOUSTON',
        city: 'Houston',
        state: 'TX',
        years: [
            {
                year: '2024',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 100,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 97,
                        precipitation: 0,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 96,
                        precipitation: 0,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 100,
                        precipitation: 0,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 85,
                        precipitation: 5,
                    },
                ],
            },
            {
                year: '2023',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 100,
                        precipitation: 10,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 10,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 10,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 95,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.STORM,
                        temperature: 90,
                        precipitation: 50,
                    },
                ],
            },
            {
                year: '2022',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.RAIN,
                        temperature: 85,
                        precipitation: 95,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 90,
                        precipitation: 10,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 90,
                        precipitation: 0,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 95,
                        precipitation: 0,
                    },
                ],
            },
        ],
    },
    {
        zipcode: 'ANCHORAGE',
        city: 'Anchorage',
        state: 'AK',
        years: [
            {
                year: '2024',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.STORM,
                        temperature: 0,
                        precipitation: 100,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.STORM,
                        temperature: 0,
                        precipitation: 90,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 5,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 5,
                        precipitation: 30,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 5,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SUN,
                        temperature: 0,
                        precipitation: 0,
                    },
                ],
            },
            {
                year: '2023',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.SUN,
                        temperature: 0,
                        precipitation: 0,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.SUN,
                        temperature: 8,
                        precipitation: 0,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 10,
                        precipitation: 20,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 6,
                        precipitation: 20,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 5,
                        precipitation: 30,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 5,
                        precipitation: 50,
                    },
                ],
            },
            {
                year: '2022',
                days: [
                    {
                        day: 'Wednesday',
                        weather: weatherState.STORM,
                        temperature: 0,
                        precipitation: 100,
                    },

                    {
                        day: 'Thursday',
                        weather: weatherState.STORM,
                        temperature: 5,
                        precipitation: 100,
                    },
                    {
                        day: 'Friday',
                        weather: weatherState.SUN,
                        temperature: 5,
                        precipitation: 5,
                    },
                    {
                        day: 'Saturday',
                        weather: weatherState.SUN,
                        temperature: 5,
                        precipitation: 0,
                    },
                    {
                        day: 'Sunday',
                        weather: weatherState.SUN,
                        temperature: 5,
                        precipitation: 0,
                    },
                    {
                        day: 'Monday',
                        weather: weatherState.SEMI_SUN,
                        temperature: 5,
                        precipitation: 50,
                    },
                ],
            },
        ],
    },
];

export const revenues = [
    {
        zipcode: 'KIRKLAND',
        data: [
            {
                orderYear: '2022',
                orderLineAmount: 150000,
                id: '1',
            },
            {
                orderYear: '2023',
                orderLineAmount: 200000,
                id: '2',
            },
            {
                orderYear: '2024',
                orderLineAmount: 130000,
                id: '3',
            },
        ],
    },
    {
        zipcode: 'BOTHELL',
        data: [
            {
                orderYear: '2022',
                orderLineAmount: 130000,
                id: '1',
            },
            {
                orderYear: '2023',
                orderLineAmount: 160000,
                id: '2',
            },
            {
                orderYear: '2024',
                orderLineAmount: 100000,
                id: '3',
            },
        ],
    },
    {
        zipcode: 'REDMOND',
        data: [
            {
                orderYear: '2022',
                orderLineAmount: 160000,
                id: '1',
            },
            {
                orderYear: '2023',
                orderLineAmount: 220000,
                id: '2',
            },
            {
                orderYear: '2024',
                orderLineAmount: 140000,
                id: '3',
            },
        ],
    },
    {
        zipcode: 'SACRAMENTO',
        data: [
            {
                orderYear: '2022',
                orderLineAmount: 110000,
                id: '1',
            },
            {
                orderYear: '2023',
                orderLineAmount: 100000,
                id: '2',
            },
            {
                orderYear: '2024',
                orderLineAmount: 80000,
                id: '3',
            },
        ],
    },
    {
        zipcode: 'HOUSTON',
        data: [
            {
                orderYear: '2024',
                orderLineAmount: 130000,
                id: '1',
            },
            {
                orderYear: '2023',
                orderLineAmount: 100000,
                id: '2',
            },
            {
                orderYear: '2022',
                orderLineAmount: 110000,
                id: '3',
            },
        ],
    },
    {
        zipcode: 'ANCHORAGE',
        data: [
            {
                orderYear: '2022',
                orderLineAmount: 100000,
                id: '1',
            },
            {
                orderYear: '2023',
                orderLineAmount: 130000,
                id: '2',
            },
            {
                orderYear: '2024',
                orderLineAmount: 100000,
                id: '3',
            },
        ],
    },
];

export const sellsThrough = [
    {
        zipcode: 'KIRKLAND',
        data: {
            storeId: 12345,
            sellThrough: {
                2024: 75.5,
                2023: 78.2,
                2022: 80.5,
            },
        },
    },
    {
        zipcode: 'BOTHELL',
        data: {
            storeId: 12333,
            sellThrough: {
                '2024': 85.5,
                '2023': 71.2,
                '2022': 70.3,
            },
        },
    },
    {
        zipcode: 'REDMOND',
        data: {
            storeId: 12345,
            sellThrough: {
                '2024': 70.0,
                '2023': 68.2,
                '2022': 50.3,
            },
        },
    },
    {
        zipcode: 'SACRAMENTO',
        data: {
            storeId: 12345,
            sellThrough: {
                '2024': 71.8,
                '2023': 58.2,
                '2022': 60.3,
            },
        },
    },
    {
        zipcode: 'HOUSTON',
        data: {
            storeId: 12345,
            sellThrough: {
                '2024': 78.8,
                '2023': 56.2,
                '2022': 70.0,
            },
        },
    },
    {
        zipcode: 'ANCHORAGE',
        data: {
            storeId: 12345,
            sellThrough: {
                '2024': 65.5,
                '2023': 70.0,
                '2022': 72.1,
            },
        },
    },
];

export const retailValues = [
    {
        zipcode: 'KIRKLAND',
        data: {
            id: 12345,
            retailValue: 30.0,
        },
    },
    {
        zipcode: 'BOTHELL',
        data: {
            id: 12345,
            retailValue: 37.5,
        },
    },
    {
        zipcode: 'REDMOND',
        data: {
            id: 12345,
            retailValue: 49.7,
        },
    },
    {
        zipcode: 'SACRAMENTO',
        data: {
            id: 12345,
            retailValue: 22.2,
        },
    },
    {
        zipcode: 'HOUSTON',
        data: {
            id: 12345,
            retailValue: 33.3,
        },
    },
    {
        zipcode: 'ANCHORAGE',
        data: {
            id: 12345,
            retailValue: 62.5,
        },
    },
];

export const programs = {
    status: 'success',
    data: [
        {id: 1, name: 'PROG123'},
        {id: 2, name: 'PROG456'},
        {id: 3, name: 'PROG789'},
        {id: 4, name: 'ANN PACKS'},
    ],

    message: 'List of active program codes associated with active item categories.',
};

export const categories = {
    status: 'success',
    data: [
        {id: 1, name: 'CAT001'},
        {id: 2, name: 'CAT002'},
        {id: 3, name: 'CAT003'},
        {id: 4, name: '4PK ANNUAL'},
    ],
    message: 'List of active item category codes.',
};

export const retailers = {
    status: 'success',
    data: [
        {
            code: '1',
            name: 'AM0001',
        },
        {
            code: '2',
            name: 'CM0001',
        },
        {
            code: '3',
            name: 'TM0001',
        },
        {
            code: '4',
            name: 'WM0001',
        },
        {
            code: '5',
            name: 'WM0002',
        },
    ],
};

export const storeRegions = {
    status: 'success',
    data: [
        {
            id: 1,
            customer_code: 'P001',
            name: 'BOTHELL',
        },
        {
            id: 2,
            customer_code: 'P002',
            name: 'REDMOND',
        },
        {
            id: 3,
            customer_code: 'P003',
            name: 'KIRKLAND',
        },
        {
            id: 4,
            customer_code: 'P004',
            name: 'ANCHORAGE',
        },
        {
            id: 5,
            customer_code: 'P005',
            name: 'SACRAMENTO',
        },
        {
            id: 6,
            customer_code: 'P006',
            name: 'Houston',
        },
    ],
};

export const storeGroups = {
    status: 'success',
    data: [
        {id: 1, name: 'GROUP 1'},
        {id: 2, name: 'GROUP 2'},
        {id: 3, name: 'GROUP 3'},
        {id: 4, name: 'GROUP 4'},
    ],

    message: 'List of active domain codes associated with customers.',
};

export const productCodes = {
    status: 'success',
    data: [
        {id: 1, name: '37748123'},
        {id: 2, name: '31238185'},
        {id: 3, name: '31238333'},
        {id: 4, name: '37748000'},
    ],
};

export const chartTypes = {
    LINE: 'line',
    BAR: 'bar',
};

export const outerHeaders = [
    {
        header: 'Store',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '75px',
        height: '35px',
        field: 'storeName',
    },
    {
        header: 'City',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '100px',
        height: '35px',
        field: 'city',
    },
    {
        header: 'State',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '65px',
        height: '35px',
        field: 'state',
    },
    {
        header: 'Ship Zone',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '100px',
        height: '35px',
        field: 'ship_zone',
    },
    {
        header: 'Program',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '100px',
        height: '35px',
        field: 'program',
    },
    {
        header: 'Category',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '100px',
        height: '35px',
        field: 'category',
    },
    {
        header: 'Product Code',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '120px',
        height: '35px',
        field: 'product_code',
    },
    {
        header: 'Item Description',
        rowSpan: 2,
        colSpan: undefined,
        sortable: true,
        frozen: true,
        width: '140px',
        height: '35px',
        field: 'item_description',
    },
    {
        header: 'Store Inventory',
        rowSpan: undefined,
        colSpan: 2,
        sortable: false,
        frozen: false,
        width: '180px',
        height: '35px',
        field: 'store_inventory',
    },
    {
        header: 'Required',
        rowSpan: undefined,
        colSpan: 2,
        sortable: false,
        frozen: false,
        width: '180px',
        height: '35px',
        field: 'required',
    },
    {
        header: 'On Order',
        rowSpan: 2,
        colSpan: undefined,
        sortable: false,
        frozen: false,
        width: '95px',
        height: '35px',
        field: 'on_order',
    },
    {
        header: 'Availability',
        rowSpan: undefined,
        colSpan: 3,
        sortable: false,
        frozen: false,
        width: '450px',
        height: '35px',
        field: 'availability',
    },
    {
        header: 'Monday 05/06/2024',
        rowSpan: undefined,
        colSpan: 3,
        sortable: false,
        frozen: false,
        width: '250px',
        height: '35px',
        field: 'forecast_monday',
    },
];

export const innerHeaders = [
    {
        header: 'On Hand',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'store_inventory_on_hand',
    },
    {
        header: 'Days',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'store_inventory_days',
    },
    {
        header: 'Qty',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'required_qty',
    },
    {
        header: 'Shelves',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'required_shelves',
    },
    {
        header: 'Qty',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'availability_qty',
    },
    {
        header: 'Updated Qty',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'availability_updated_qty',
    },
    {
        header: 'Updated Shelves',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'availability_updated_shelves',
    },
    {
        header: 'Qty',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'forecast_monday_qty',
    },
    {
        header: 'Shelves',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'forecast_monday_shelves',
    },
    {
        header: 'Carts',
        rowSpan: undefined,
        colSpan: undefined,
        sortable: true,
        frozen: false,
        width: '',
        height: '',
        field: 'forecast_monday_carts',
    },
];

export const bodyColumns = [
    {
        field: 'storeName',
        columnKey: 'storeName',
        frozen: true,
        editable: false,
    },
    {
        field: 'city',
        columnKey: 'city',
        frozen: true,
        editable: false,
    },
    {
        field: 'state',
        columnKey: 'state',
        frozen: true,
        editable: false,
    },
    {
        field: 'ship_zone',
        columnKey: 'ship_zone',
        frozen: true,
        editable: false,
    },
    {
        field: 'program',
        columnKey: 'program',
        frozen: true,
        editable: false,
    },
    {
        field: 'category',
        columnKey: 'category',
        frozen: true,
        editable: false,
    },
    {
        field: 'product_code',
        columnKey: 'product_code',
        frozen: true,
        editable: false,
    },
    {
        field: 'item_description',
        columnKey: 'item_description',
        frozen: true,
        editable: false,
    },
    {
        field: 'store_inventory_on_hand',
        columnKey: 'store_inventory_on_hand',
        frozen: false,
        editable: false,
    },
    {
        field: 'store_inventory_days',
        columnKey: 'store_inventory_days',
        frozen: false,
        editable: false,
    },
    {
        field: 'required_qty',
        columnKey: 'required_qty',
        frozen: false,
        editable: false,
    },
    {
        field: 'required_shelves',
        columnKey: 'required_shelves',
        frozen: false,
        editable: false,
    },
    {
        field: 'on_order',
        columnKey: 'on_order',
        frozen: false,
        editable: false,
    },
    {
        field: 'availability_qty',
        columnKey: 'availability_qty',
        frozen: false,
        editable: false,
    },
    {
        field: 'availability_updated_qty',
        columnKey: 'availability_updated_qty',
        frozen: false,
        editable: false,
    },
    {
        field: 'availability_updated_shelves',
        columnKey: 'availability_updated_shelves',
        frozen: false,
        editable: false,
    },
    {
        field: 'forecast_monday_qty',
        columnKey: 'forecast_monday_qty',
        frozen: false,
        editable: true,
    },
    {
        field: 'forecast_monday_shelves',
        columnKey: 'forecast_monday_shelves',
        frozen: false,
        editable: true,
    },
    {
        field: 'forecast_monday_carts',
        columnKey: 'forecast_monday_carts',
        frozen: false,
        editable: true,
    },
];

export const columnHeaderSelector =
    '[data-pc-name="datatable"][pr_id_4] > [data-pc-section="wrapper"] > [data-pc-section="table"] > [data-pc-section="thead"] > tr > th:nth-child';

export const initialValues = {
    firstName: 'Elon',
    lastName: 'Mask',
    email: 'starlink@mail.com',
    phoneNumber: '(999)999-9999',
    contractNumber: '11111',
};
