import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import {ERROR_CODE, ERROR_TEXT, LINK_TEXT} from './appRoutesConstants';
import {LINKS} from '../globalConstants';
import GenericErrorPage from '../components/Common/GenericErrorPage/GeneicErrorPage';
import ProtectedRoute from '../components/Auth/ProtectedRoute/ProtectedRoute';
import LandingPage from '../components/LandingPage/LandingPage';
import HomePage from '../components/HomePage/HomePage';
import CreateOrdersPage from '../components/CreateOrders/Page/CreateOrdersPage';
import Reporting from '../components/Reporting/Reporting';
import Login from '../components/Auth/Login/Login';
import ResetPassword from '../components/Auth/ResetPassword/ResetPassword';
import ChangePassword from '../components/Auth/ChangePassword/ChangePassword';
import RegistrationForm from '../components/Auth/RegistrationForm/RegistrationForm';
import UserProfile from '../components/UserProfile/UserProfile';
import ApiConfiguration from '../components/ApiConfiguration/ApiConfiguration';
import UserAgreement from '../components/UserAgreement/UserAgreement';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

const AppRoutes = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route path={LINKS.LANDING} element={<LandingPage />} />
                <Route path={LINKS.REGISTRATION} element={<RegistrationForm />} />
                <Route path={LINKS.LOGIN} element={<Login />} />
                <Route path={LINKS.USER_AGREEMENT} element={<UserAgreement />} />
                <Route path={LINKS.POLICY_PRIVACY} element={<PrivacyPolicy />} />
                <Route path={LINKS.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={LINKS.CHANGE_PASSWORD} element={<ChangePassword />} />
                <Route path={LINKS.HOME} element={<ProtectedRoute component={HomePage} />} />
                <Route path={LINKS.USER_PROFILE} element={<ProtectedRoute component={UserProfile} />} />
                <Route path={LINKS.API_CONFIGURATION} element={<ProtectedRoute component={ApiConfiguration} />} />
                <Route path={LINKS.REPORTING} element={<ProtectedRoute component={Reporting} />} />
                <Route path={LINKS.CREATE_ORDERS} element={<ProtectedRoute component={CreateOrdersPage} />} />
                <Route
                    path="*"
                    element={
                        <GenericErrorPage
                            errorCode={ERROR_CODE}
                            errorText={ERROR_TEXT}
                            linkTo={LINKS.HOME}
                            linkText={LINK_TEXT}
                        />
                    }
                />
            </Route>,
        ),
    );
    return (
        <div className="content">
            <RouterProvider router={router} />
        </div>
    );
};

export default AppRoutes;
