import React, {useEffect} from 'react';
import {unloadCallback} from '../../../globalUtils';
import Prompt from 'react-router-prompt';
import {Button} from 'primereact/button';
import styles from './PromptIfDirty.module.scss';

const PromptIfDirty = ({dirty}) => {
    useEffect(() => {
        if (dirty) {
            window.addEventListener('beforeunload', unloadCallback);
        } else {
            window.removeEventListener('beforeunload', unloadCallback);
        }

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, [dirty]);

    return (
        <Prompt when={dirty}>
            {(prompt) =>
                prompt ? (
                    <div className={styles.prompt}>
                        <h4>Leave page?</h4>
                        <p>Changes you made may not be saved!</p>
                        <div className={styles['prompt-buttons']}>
                            <Button className="leave" label="Leave" type="button" onClick={prompt.onCancel} />
                            <Button
                                className="cancel"
                                label="Cancel"
                                type="button"
                                severity="secondary"
                                onClick={prompt.onCancel}
                            />
                        </div>
                    </div>
                ) : null
            }
        </Prompt>
    );
};

export default PromptIfDirty;
