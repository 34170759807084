// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisrationForm_registration-form__NwarT {
  width: 100%;
  overflow: auto;
}
.RegisrationForm_registration-form__NwarT form {
  width: 90%;
  max-width: 580px;
  margin: 20px auto;
  padding: 9px 25px;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.1411764706), 0 9px 46px 8px rgba(0, 0, 0, 0.1215686275);
}
.RegisrationForm_registration-form__NwarT form continue:hover {
  cursor: pointer;
}
.RegisrationForm_registration-form__NwarT h1,
.RegisrationForm_registration-form__NwarT h2,
.RegisrationForm_registration-form__NwarT h3,
.RegisrationForm_registration-form__NwarT p {
  text-align: center;
}
.RegisrationForm_registration-form__NwarT h1 {
  margin: 15px 0 0;
  font-size: 25px;
}
.RegisrationForm_registration-form__NwarT .RegisrationForm_checkbox__Rh\\+Bp {
  margin: 0;
}
.RegisrationForm_registration-form__NwarT .RegisrationForm_checkbox__Rh\\+Bp a:visited {
  color: #2196f3;
}

@media only screen and (max-width: 993px) {
  .RegisrationForm_registration-form__NwarT {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/RegistrationForm/RegisrationForm.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,cAAA;AADJ;AAGI;EACI,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,wIACI;AAFZ;AAOY;EACI,eAAA;AALhB;AAUI;;;;EAII,kBAAA;AARR;AAWI;EACI,gBAAA;EACA,eAAA;AATR;AAYI;EACI,SAAA;AAVR;AAYQ;EACI,cAAA;AAVZ;;AAeA;EACI;IACI,YAAA;EAZN;AACF","sourcesContent":["@import '../../../assets/scss/variables';\n\n.registration-form {\n    width: 100%;\n    overflow: auto;\n\n    form {\n        width: 90%;\n        max-width: 580px;\n        margin: 20px auto;\n        padding: 9px 25px;\n        border: 1px solid white;\n        border-radius: 5px;\n        box-shadow:\n            0 11px 15px -7px #0003,\n            0 24px 38px 3px #00000024,\n            0 9px 46px 8px #0000001f;\n\n        continue {\n            &:hover {\n                cursor: pointer;\n            }\n        }\n    }\n\n    h1,\n    h2,\n    h3,\n    p {\n        text-align: center;\n    }\n\n    h1 {\n        margin: 15px 0 0;\n        font-size: 25px;\n    }\n\n    .checkbox {\n        margin: 0;\n\n        a:visited {\n            color: #2196f3;\n        }\n    }\n}\n\n@media only screen and (max-width: $width-md) {\n    .registration-form {\n        width: 100vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration-form": `RegisrationForm_registration-form__NwarT`,
	"checkbox": `RegisrationForm_checkbox__Rh+Bp`
};
export default ___CSS_LOADER_EXPORT___;
