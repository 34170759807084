import React, {useEffect} from 'react';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import {PrimeReactProvider} from 'primereact/api';

import AppRoutes from './appRoutes';

import {setLogIn} from './store/slices/logIn/logInSlice';
import {useAppDispatch} from './hooks/redux';

import './App.scss';

const value = {
    ripple: true,
};

function App() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const isLogged = localStorage.getItem('auth-token');
        if (isLogged) {
            dispatch(setLogIn({isLogged: true}));
        }
    }, []);

    return (
        <div className="app">
            <PrimeReactProvider value={value}>
                <AppRoutes />
            </PrimeReactProvider>
        </div>
    );
}

export default App;
