import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {IconField} from 'primereact/iconfield';
import {InputIcon} from 'primereact/inputicon';
import {InputText} from 'primereact/inputtext';
import {debounce} from 'lodash';

import {selectMappedOrders} from '../../../store/slices/orders/selectors';

import styles from './SearchInput.module.scss';

interface ISearchInput {
    setParams?: (arg: any) => void;
}

const SearchInput: FC<ISearchInput> = ({setParams}) => {
    const orders = useSelector(selectMappedOrders);

    //ToDo remove after I get real back end
    const getSearchValue = (searchTerm: string) => {
        const term = searchTerm.toLowerCase().trim();

        return orders.filter(
            (obj) =>
                obj.item_description.toLowerCase().includes(term) ||
                obj.product_code.toLowerCase().includes(term) ||
                obj.storeName.toLowerCase().includes(term) ||
                obj.city.toLowerCase().includes(term) ||
                obj.ship_zone.toLowerCase().includes(term) ||
                obj.state.toLowerCase().includes(term),
        );
    };

    const onGlobalSearch = debounce((event) => {
        setParams(getSearchValue(event.target.value));
    }, 500);

    return (
        <IconField className="h-full">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText type="search" onInput={onGlobalSearch} placeholder="Search..." className={styles.search} />
        </IconField>
    );
};

export default SearchInput;
