import {combineReducers, configureStore} from '@reduxjs/toolkit';
import logIn from './slices/logIn';
import snackbar from './slices/snackbar';
import revenue from './slices/revenue';
import orders from './slices/orders';
import forecast from './slices/forecast';
import filters from './slices/filters';
import sellsThrough from './slices/sellThrough';
import retailValue from './slices/retailValue';
import {snackbarMiddleware} from './slices/snackbar/middleware';

const rootReducer = combineReducers({
    logIn,
    snackbar,
    revenue,
    orders,
    forecast,
    filters,
    sellsThrough,
    retailValue,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(snackbarMiddleware),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
