// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_home-page__ahKqU {
  display: flex;
  width: 100%;
  height: 100%;
}
.HomePage_home-page__ahKqU .HomePage_content__EGvy4 {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/HomePage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,SAAA;AACR","sourcesContent":[".home-page {\n    display: flex;\n    width: 100%;\n    height: 100%;\n\n    .content {\n        flex: 1 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-page": `HomePage_home-page__ahKqU`,
	"content": `HomePage_content__EGvy4`
};
export default ___CSS_LOADER_EXPORT___;
