import createGenericSlice from '../createGenericSlice';
import {retailValueNames} from './constants';
import {fetchRetailValue} from './thunks';

const initialState = {
    id: 12345,
    retailValue: 30.0,
};

export const retailValueSlice = createGenericSlice({
    name: retailValueNames.fetchRetailValue,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRetailValue.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = payload.data;
            }
        });
    },
});
export const {resetSlice} = retailValueSlice.actions;
