import React, {FC, ReactNode, useRef, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import {SplitButton} from 'primereact/splitbutton';
import classNames from 'classnames';

import {LINKS} from '../../../globalConstants';

import MenuDivider from '../MenuDivider/Menudivider';

import styles from './LeftSideBar.module.scss';

interface IProps {
    children?: ReactNode;
}

const LeftSideBar: FC<IProps> = ({children}) => {
    const [isVisible, setIsVisible] = useState(true);

    const nodeRef = useRef(null);

    const toggleVisible = () => {
        setIsVisible((prevState) => !prevState);
    };

    const defaultTimeout = 400;

    const defaultStyle = {
        transition: `display ${defaultTimeout}ms ease-in-out`,
        display: 'flex',
    };

    const transitionStyles = {
        entering: {display: 'flex'},
        entered: {display: 'flex'},
        exiting: {display: 'none'},
        exited: {display: 'none'},
    };

    const navigate = useNavigate();

    const items = [
        {
            label: 'Create Orders',
            icon: 'pi pi-receipt',
            command: () => {
                navigate(LINKS.CREATE_ORDERS);
            },
        },
    ];

    return (
        <div className={classNames(styles['leftbar-container'], isVisible ? '' : styles['leftbar-hidden'])}>
            <div className={classNames(styles['leftbar-content'])}>
                <div className={styles.logo}></div>
                <div className={styles.menu}>
                    <NavLink to={LINKS.HOME} className={styles['nav-link']}>
                        <i className="pi pi-home"></i> Home
                    </NavLink>
                    <NavLink to={LINKS.REPORTING} className={styles['nav-link']}>
                        <i className="pi pi-chart-bar"></i> Reporting
                    </NavLink>
                    <SplitButton
                        label="Replenishment"
                        icon="pi pi-refresh"
                        model={items}
                        text
                        menuClassName="split-button"
                        className={styles['nav-button']}
                    />
                </div>
                <MenuDivider />
                <Transition in={isVisible} nodeRef={nodeRef} timeout={defaultTimeout}>
                    {(state) => (
                        <div
                            ref={nodeRef}
                            className={styles.children}
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                            }}>
                            {children}
                        </div>
                    )}
                </Transition>
            </div>
            <button type="button" className={styles['bar-toggle-btn']} onClick={toggleVisible}>
                <i className="pi pi-chevron-left" />
            </button>
        </div>
    );
};

export default LeftSideBar;
