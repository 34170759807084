import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {forecastNames} from './constants';
import {getForecast} from '../../../api/forecast/forecast';

export const fetchForecast = createAsyncThunk(
    `${slicesNames.forecast}/${forecastNames.fetchForecast}`,
    async (data: string, {rejectWithValue}) => {
        try {
            return await getForecast(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
