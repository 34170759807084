import React, {FC, useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {DataTable} from 'primereact/datatable';
import {Column, ColumnEditorOptions, ColumnEvent} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Toast} from 'primereact/toast';
import {MultiSelect, MultiSelectChangeEvent} from 'primereact/multiselect';
import classNames from 'classnames';
import {cloneDeep} from 'lodash';

import {selectMappedOrders, selectOrders, selectTotal} from '../../../store/slices/orders/selectors';
import {IMappedOrdersItem, IOrdersItem} from '../../../api/orders/types';
import {bodyColumns, initialParams, innerHeaders, outerHeaders} from '../constants';
import {IBodyColumn, IHeaderColumn, IOrdersParams, ISetOrdersParams} from '../types';

import {useAppDispatch} from '../../../hooks/redux';
import {updateOrders} from '../../../store/slices/orders/ordersSlice';
import {getValidCellValue} from '../../../globalUtils';
import {convertStringToColumns, getVisibleColumns, getVisibleNames, onHeaderMove, removeImportantRule} from '../utils';

import Footer from '../../Common/Footer/Footer';
import SearchInput from '../../Common/SearchInput/SearchInput';

import styles from './CreateOrderList.module.scss';

interface IProps {
    params: IOrdersParams;
    setParams: ISetOrdersParams;
    isLoading: boolean;
    selectedOrder: IMappedOrdersItem;
    setSelectedOrder: (arg: IMappedOrdersItem) => void;
    getOrders: () => Promise<void>;
}

const CreateOrdersList: FC<IProps> = ({params, setParams, isLoading, selectedOrder, setSelectedOrder}) => {
    const tableRef = useRef(null);
    const toast = useRef<Toast>(null);

    const [savedOrders, setSavedOrders] = useState([]);
    const [totalLength, setTotalLength] = useState(0);
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState({name: 'method1', id: 1});
    const [pagination, setPagination] = useState({first: 0, rows: 10});
    const [columns, setColumns] = useState({top: outerHeaders, bottom: innerHeaders, body: bodyColumns});
    const [dragStartColumn, setDragStartColumn] = useState(null);
    const [dragTargetColumn, setDragTargetColumn] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState([...outerHeaders]);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const initialOrders = useSelector(selectOrders);
    const orders = useSelector(selectMappedOrders);
    const total = useSelector(selectTotal);
    const columnsOptions = outerHeaders.filter((el) => el.field !== 'storeName');
    const initialColumns = {top: outerHeaders, bottom: innerHeaders, body: bodyColumns};
    const frozenFields = columns.top
        .map((el) => {
            if (el.frozen) {
                return el.field;
            }
        })
        .filter((el) => el);

    const dispatch = useAppDispatch();

    const options = [
        {name: 'method1', id: 1},
        {name: 'method2', id: 2},
        {name: 'method3', id: 3},
    ];

    //ToDo remove after I get real bec end
    const getSearchParams = (searchedOrders: IOrdersItem[]) => {
        setLoading(true);
        setTimeout(() => {
            if (searchedOrders.length) {
                setSavedOrders(searchedOrders);
                setTotalLength(searchedOrders.length);
            } else {
                setSavedOrders([]);
                setTotalLength(0);
            }
            setLoading(false);
        }, 700);
    };

    const show = (message: string) => {
        toast.current?.show({severity: 'success', summary: 'Success', detail: message, life: 5000});
    };

    const refreshAvailability = useCallback(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            show('Availability was refreshed successfully.');
        }, 1000);
    }, []);

    const saveShipments = useCallback(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            show('Shipments was saved successfully.');
        }, 1000);
    }, []);

    const processChanges = useCallback(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            show('Changes was processed successfully.');
        }, 1000);
    }, []);

    const calculateRequired = useCallback(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            show('Calculated successfully.');
        }, 500);
    }, []);

    const onColumnsToggle = (event: MultiSelectChangeEvent) => {
        setVisibleColumns(event.value);
        const newColumns = getVisibleColumns(initialColumns, columns, event.value, false);
        localStorage.setItem('visibleColumns', getVisibleNames(newColumns.top));
        setColumns(newColumns);
    };

    const handleShow = () => {
        setIsDropdownVisible(true);
    };

    const handleHide = () => {
        setIsDropdownVisible(false);
    };

    const header = (
        <div className={styles['table-header']}>
            <Toast ref={toast} />
            <div className={styles.left}>
                <h3>Create Orders</h3>
                <Button label="Refresh Availability" raised onClick={refreshAvailability} />
                <Button label="Save Shipments" raised onClick={saveShipments} />
                <Button label="Process Changes" raised onClick={processChanges} />
            </div>
            <div className={styles.right}>
                <div className={styles['right-item']}>
                    <label htmlFor="">Days On Hand</label>
                    <InputText type="number" className={styles['days-on-hand']} min={0} defaultValue={5} />
                </div>
                <div className={styles['right-item']}>
                    <label htmlFor="">Velocity Method</label>
                    <Dropdown
                        value={method}
                        options={options}
                        optionLabel="name"
                        className={styles.method}
                        onChange={(e) => setMethod(e.value)}
                    />
                </div>
                <div className={styles['right-item']}>
                    <Button label="Calculate Required" raised onClick={calculateRequired} />
                </div>
                <div className={styles['right-item']}>
                    <SearchInput setParams={getSearchParams} />
                </div>
                <div className={styles.visibility}>
                    <MultiSelect
                        value={visibleColumns}
                        options={columnsOptions}
                        optionLabel="header"
                        onChange={onColumnsToggle}
                        onShow={handleShow}
                        onHide={handleHide}
                        className="w-full column-visibility"
                        dropdownIcon={isDropdownVisible ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    />
                </div>
            </div>
        </div>
    );

    const updateCellValue = (rowData: IMappedOrdersItem, newValue: string, field: string) => {
        const updatedOrder = {...rowData};
        updatedOrder[field] = +newValue;

        const mappedBackOrder = {
            ...updatedOrder,
            inventory: {
                on_hand: updatedOrder.inventory_on_hand,
                days: updatedOrder.inventory_days,
            },
            required: {
                qty: updatedOrder.required_qty,
                shelves: updatedOrder.required_shelves,
            },
            availability: {
                qty: updatedOrder.availability_qty,
                updated_qty: updatedOrder.availability_updated_qty,
                updated_shelves: updatedOrder.availability_updated_shelves,
            },
            forecast: {
                monday: {
                    date: updatedOrder.forecast_monday_date,
                    qty: updatedOrder.forecast_monday_qty,
                    shelves: updatedOrder.forecast_monday_shelves,
                    carts: updatedOrder.forecast_monday_carts,
                },
            },
        };
        const updatedOrders = initialOrders.map((order) => {
            if (order.id === rowData.id) {
                return mappedBackOrder;
            } else {
                return order;
            }
        });

        //ToDo remove after I got real bak end
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 700);
        dispatch(updateOrders(updatedOrders));
    };

    const onCellEditComplete = (e: ColumnEvent) => {
        const {rowData, newValue, field} = e;
        updateCellValue(rowData, newValue, field);
    };

    const cellEditor = (options: ColumnEditorOptions, integer = true) => {
        return integer ? integerEditor(options) : decimalEditor(options);
    };

    const integerEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                className={styles['input-editor']}
                type="number"
                min="0"
                keyfilter="int"
                value={options.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    options.editorCallback(getValidCellValue(e.target.value))
                }
            />
        );
    };

    const decimalEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                className={styles['input-editor']}
                type="number"
                min="0"
                value={options.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    options.editorCallback(getValidCellValue(e.target.value))
                }
            />
        );
    };
    const editedCellBodyTemplate = (rowData: IOrdersItem, field: string) => {
        return (
            <div className={styles['editable-cell']}>
                <span>{rowData[field]}</span>
                <i className="pi pi-pencil"></i>
            </div>
        );
    };

    const onPageSelect = useCallback(({first, rows}) => {
        setPagination({first, rows});
    }, []);

    const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, column: IHeaderColumn) => {
        setDragStartColumn(column);
    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };
    const dropHandler = (e: React.DragEvent<HTMLDivElement>, column: IHeaderColumn) => {
        e.preventDefault();
        setDragTargetColumn(column);
    };

    const headerTemplate = (column: IHeaderColumn) => {
        return (
            <div
                className="custom-title"
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, column)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropHandler(e, column)}>
                {column.header}
            </div>
        );
    };

    const getHeaderOffset = (columns: IHeaderColumn[], column: IHeaderColumn) => {
        const onlyFrozenColumns = columns.filter((el) => el.frozen);
        const targetIndex = onlyFrozenColumns.findIndex((element) => element.field === column.field);
        const subArray = onlyFrozenColumns.slice(0, targetIndex + 1);
        return (
            subArray.reduce(
                (accumulator, currentValue) => accumulator + parseInt(currentValue.width.replace('px', '')),
                0,
            ) - parseInt(column.width.replace('px!important', ''))
        );
    };

    const getBodyOffset = (columns: IHeaderColumn[], column: IBodyColumn) => {
        const onlyFrozenColumns = columns.filter((el) => el.frozen);
        const targetIndex = onlyFrozenColumns.findIndex((element) => element.field === column.field);
        const subArray = onlyFrozenColumns.slice(0, targetIndex + 1);
        const currentHeader = onlyFrozenColumns.filter((el) => el.field === column.field);
        return (
            subArray.reduce(
                (accumulator, currentValue) => accumulator + parseInt(currentValue?.width?.replace('px', '')),
                0,
            ) - parseInt(currentHeader[0].width.replace('px', ''))
        );
    };

    const getWidthAfterResize = (e) => {
        removeImportantRule();

        const newTopHeaders = columns.top.map((el) => {
            if (el.field === e.column.props.field) {
                const newCol = el;
                newCol.width = `${parseInt(newCol.width.replace('px', '')) + e.delta}px`;
                return newCol;
            } else {
                return el;
            }
        });

        setColumns((prevState) => ({
            ...prevState,
            top: newTopHeaders,
        }));

        const columnPosition = frozenFields.reverse().findIndex((el) => el === e.column.props.field);
        for (let i = 1; i < columnPosition + 1; i++) {
            setTimeout(forceUpdate, i * 100);
        }
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                {columns.top.map((col) => {
                    return (
                        <Column
                            key={col.field}
                            rowSpan={col.rowSpan}
                            colSpan={col.colSpan}
                            sortable={col.sortable}
                            field={col.field}
                            sortField={col.field}
                            columnKey={col.field}
                            headerClassName={classNames(styles.header, 'dark-header', 'draggable')}
                            headerStyle={{
                                width: `${col.width}`,
                                maxWidth: `${col.width}`,
                                height: '36px',
                                position: 'sticky',
                                zIndex: col.frozen ? 1 : 0,
                                top: '0px',
                                left: col.frozen ? getHeaderOffset(columns.top, col) : '0px',
                            }}
                            header={headerTemplate(col)}></Column>
                    );
                })}
            </Row>
            <Row>
                {columns.bottom.map((col) => (
                    <Column
                        key={col.field}
                        header={col.header}
                        rowSpan={col.rowSpan}
                        colSpan={col.colSpan}
                        sortable={col.sortable}
                        frozen={col.frozen}
                        field={col.field}
                        sortField={col.field}
                        columnKey={col.field}
                        headerClassName={classNames(styles.header, 'mid-header')}
                        headerStyle={{width: col.width, height: col.height}}
                    />
                ))}
            </Row>
        </ColumnGroup>
    );

    useEffect(() => {
        setDragStartColumn(null);
        setDragTargetColumn(null);
    }, [columns]);

    useEffect(() => {
        dragTargetColumn && onHeaderMove(dragStartColumn, dragTargetColumn, columns, setColumns);
    }, [dragTargetColumn]);

    useEffect(() => {
        setTotalLength(total);
    }, [total]);

    useEffect(() => {
        setSavedOrders(orders);
    }, [orders]);

    useEffect(() => {
        if (params.navigation.page.from !== pagination.first || params.navigation.page.size !== pagination.rows) {
            setParams((oldParams) => {
                const params = cloneDeep(oldParams);
                params.navigation.page.from = pagination.first;
                params.navigation.page.size = pagination.rows;
                return params;
            });
        }
    }, [pagination, setParams, params]);

    useEffect(() => {
        const savedColumns = localStorage.getItem('visibleColumns');
        if (savedColumns) {
            const newVisibleColumns = convertStringToColumns(savedColumns, outerHeaders);
            const newColumns = getVisibleColumns(initialColumns, columns, newVisibleColumns, true);
            setVisibleColumns(newVisibleColumns);
            setColumns(newColumns);
        }
    }, []);
    return (
        <div className={classNames(styles['create-order-list'])}>
            <DataTable
                ref={tableRef}
                value={savedOrders}
                className={classNames(styles['orders-table'])}
                header={header}
                headerColumnGroup={headerGroup}
                selectionMode="single"
                selection={selectedOrder}
                editMode="cell"
                emptyMessage=""
                scrollable
                resizableColumns
                onColumnResizeEnd={getWidthAfterResize}
                columnResizeMode="expand"
                showGridlines
                onSelectionChange={(e) => setSelectedOrder(e.value)}
                loading={loading || isLoading}>
                {columns.body.map((col) => {
                    if (col.editable) {
                        return (
                            <Column
                                key={col.field}
                                field={col.field}
                                columnKey={col.columnKey}
                                frozen={col.frozen}
                                bodyStyle={{height: '35px', left: col.frozen ? getBodyOffset(columns.top, col) : ''}}
                                editor={(options: ColumnEditorOptions) => cellEditor(options, false)}
                                onCellEditComplete={onCellEditComplete}
                                body={(rowData) => editedCellBodyTemplate(rowData, col.field)}>
                                <span className="qqq"></span>
                            </Column>
                        );
                    } else {
                        return (
                            <Column
                                key={col.field}
                                field={col.field}
                                columnKey={col.columnKey}
                                frozen={col.frozen}
                                bodyStyle={{
                                    height: '35px',
                                    left: col.frozen ? getBodyOffset(columns.top, col) : '',
                                }}
                            />
                        );
                    }
                })}
            </DataTable>
            <Footer
                totalRecords={totalLength}
                initParams={initialParams}
                params={params}
                pagination={pagination}
                type="orders"
                onPageSelect={onPageSelect}
            />
        </div>
    );
};

export default CreateOrdersList;
