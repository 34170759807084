import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';
import {fetchForecast} from './thunks';
import {weather} from '../../../components/CreateOrders/constants';

export const forecastSlice = createGenericSlice({
    name: slicesNames.forecast,
    initialState: {
        data: weather[2],
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchForecast.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = payload.data;
            }
        });
    },
});
export const {resetSlice} = forecastSlice.actions;
