// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateOrders_create-orders-page__uqx5t {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateOrders/Page/CreateOrders.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADJ","sourcesContent":["@import '../../../assets/scss/variables';\n\n.create-orders-page {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    overflow-x: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-orders-page": `CreateOrders_create-orders-page__uqx5t`
};
export default ___CSS_LOADER_EXPORT___;
