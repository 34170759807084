import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import styles from './GenericErrorPage.module.scss';

interface IProps {
    errorCode: string;
    errorText: string;
    linkTo: string;
    linkText: string;
}

const GenericErrorPage: FC<IProps> = ({errorCode, errorText, linkText, linkTo}) => {
    return (
        <div className={styles['generic-error-page']}>
            <h1>{errorCode}</h1>
            <h2>{errorText}</h2>
            <Link to={linkTo}>{linkText} </Link>
        </div>
    );
};

export default GenericErrorPage;
