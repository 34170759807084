import React from 'react';

import LeftSideBar from '../Common/LeftSideBar/LeftSideBar';
import Navigation from '../Navigation/Navigation';

import styles from './HomePage.module.scss';

const HomePage = () => {
    return (
        <div className={styles['home-page']}>
            <LeftSideBar />
            <div className={styles['content']}>
                <Navigation items={null} showBreadcrumbs={false} />
                <h2>Home</h2>
            </div>
        </div>
    );
};

export default HomePage;
