import {PayloadAction} from '@reduxjs/toolkit';
import createGenericSlice, {IGenericState} from '../createGenericSlice';
import {ILogIn} from '../../../api/logIn/types';
import {fetchLogin, fetchLogOut} from './thunks';
import {slicesNames} from '../constants';

const initialState = {
    isLogged: false,
};

interface ILogInState {
    isLogged: boolean;
}

const reducers = {
    setLogIn: (state: IGenericState<ILogInState>, {payload}: PayloadAction<Partial<ILogIn>>) => {
        state.data = payload as ILogInState;
    },
};

export const logInSlice = createGenericSlice<ILogInState, typeof reducers>({
    name: slicesNames.logIn,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers,
    extraReducers: (builder) => {
        builder.addCase(fetchLogin.fulfilled, (state) => {
            state.data.isLogged = true;
        });
        builder.addCase(fetchLogOut.fulfilled, (state) => {
            state.data.isLogged = false;
        });
    },
});

export const {setLogIn} = logInSlice.actions;
