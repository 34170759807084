// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BreadCrumb_bread-crumb__NSI88 {
  margin-left: 30px;
}
.BreadCrumb_bread-crumb__NSI88 nav ol {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.BreadCrumb_bread-crumb__NSI88 nav ol li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/BreadCrumb/BreadCrumb.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAEQ;EACI,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,SAAA;EACA,UAAA;EACA,qBAAA;AAAZ;AAGgB;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;AADpB","sourcesContent":[".bread-crumb {\n    margin-left: 30px;\n\n    nav {\n        ol {\n            display: flex;\n            flex-wrap: nowrap;\n            align-items: center;\n            margin: 0;\n            padding: 0;\n            list-style-type: none;\n\n            li {\n                a {\n                    display: flex;\n                    align-items: center;\n                    text-decoration: none;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bread-crumb": `BreadCrumb_bread-crumb__NSI88`
};
export default ___CSS_LOADER_EXPORT___;
