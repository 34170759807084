import {sendRequest, urls} from '../../../src/apiRequestService';
import {requestMethod} from '../../globalConstants';
import {
    categories,
    programs,
    retailers,
    productCodes,
    storeGroups,
    storeRegions,
} from '../../components/CreateOrders/constants';

export const getRetailers = (data: Record<string, unknown>) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.RETAILERS, requestMethod.GET, data);

    //ToDo remove after get real back end
    return retailers.data;
};

export const getStoreRegions = (data: Record<string, unknown>) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.STORE_REGIONS, requestMethod.GET, data);

    //ToDo remove after get real back end
    return storeRegions.data;
};

export const getStoreGroups = (data: Record<string, unknown>) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.STORE_GROUPS, requestMethod.GET, data);

    //ToDo remove after get real back end
    return storeGroups.data;
};

export const getPrograms = (data: Record<string, unknown>) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.PROGRAMS, requestMethod.GET, data);

    //ToDo remove after get real back end
    return programs.data;
};

export const getCategories = (data: Record<string, unknown>) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.CATEGORIES, requestMethod.GET, data);

    //ToDo remove after get real back end
    return categories.data;
};

export const getProductCodes = (data: Record<string, unknown>) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.SKUSES, requestMethod.GET, data);

    //ToDo remove after get real back end
    return productCodes.data;
};
