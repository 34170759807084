import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {ordersNames} from './constants';
import {getOrders} from '../../../api/orders/orders';
import {IData} from '../../../components/CreateOrders/types';
import {IOrdersItem} from '../../../api/orders/types';

export const fetchOrders = createAsyncThunk(
    `${slicesNames.revenue}/${ordersNames.fetchOrders}`,
    async (data: IData, {rejectWithValue}) => {
        try {
            return await getOrders(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
