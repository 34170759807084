import React from 'react';
import {useSelector} from 'react-redux';
import {Chart} from 'react-chartjs-2';
import {getRevenueData} from '../utils';
import {IChartOptions} from '../../../store/slices/types';
import {chartOptions} from '../constants';
import {selectRevenue} from '../../../store/slices/revenue/selectors';

import styles from './RevenueInfo.module.scss';

const RevenueInfo = () => {
    const revenues = useSelector(selectRevenue);
    return (
        <div className={styles.revenue}>
            <h3 className={styles.title}>YTD Revenue</h3>
            <Chart type="bar" data={getRevenueData(revenues)} options={chartOptions as IChartOptions} />
        </div>
    );
};

export default React.memo(RevenueInfo);
