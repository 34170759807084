import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import {setupStore} from './store/store';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Snackbar from './components/Snackbar';
import './index.css';

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <SnackbarProvider
            className="snackBar"
            autoHideDuration={5000}
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <Snackbar />
            <App />
        </SnackbarProvider>
    </Provider>,
);

reportWebVitals();
