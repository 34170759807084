import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {revenueNames} from './constants';
import {getRevenue} from '../../../api/revenue/revenue/revenue';

export const fetchRevenue = createAsyncThunk(
    `${slicesNames.revenue}/${revenueNames.fetchRevenue}`,
    async (data: string, {rejectWithValue}) => {
        try {
            return await getRevenue(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
