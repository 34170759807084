import React from 'react';
import {Password} from 'primereact/password';
import {Divider} from 'primereact/divider';
import {Accordion, AccordionTab} from 'primereact/accordion';

import LeftSideBar from '../Common/LeftSideBar/LeftSideBar';
import Navigation from '../Navigation/Navigation';

import styles from './ApiConfiguration.module.scss';

const ApiConfiguration = () => {
    const breadCrumbItems = [{label: 'API Configuration'}];
    return (
        <div className={styles['api-configuration']}>
            <LeftSideBar />
            <div className={styles['content']}>
                <Navigation items={breadCrumbItems} showBreadcrumbs={true} />
                <div className={styles.form}>
                    <h3>API Configuration</h3>
                    <Divider />
                    <div className="p-fluid">
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="apiKey" className="col-12 lg:col-2 font-bold">
                                API Key
                            </label>
                            <div className="col-12 lg:col-4">
                                <Password value="aaaaaaaaaa" feedback={false} readOnly={true} toggleMask />
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="secretKey" className="col-12 lg:col-2 font-bold">
                                Secret Key
                            </label>
                            <div className="col-12 lg:col-4">
                                <Password value="bbbbbbbbbbbbb" feedback={false} readOnly={true} toggleMask />
                            </div>
                        </div>
                        <Divider />
                        <div className="p-field grid p-ai-start">
                            <div className="col-12 lg:col-6">
                                <Accordion multiple>
                                    <AccordionTab header="How to use API key">
                                        <p className="m-0">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                                            non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                    </AccordionTab>
                                    <AccordionTab header="How to use secret key">
                                        <p className="m-0">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                            doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                                            ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                            consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                                            Consectetur, adipisci velit, sed quia non numquam eius modi.
                                        </p>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApiConfiguration;
