// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfile_user-profile__AZbUE {
  display: flex;
  width: 100%;
  height: 100%;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF form {
  flex: 1 1;
  margin: 1rem;
  padding: 1.25rem;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF form h3 {
  margin: 0;
  text-align: left;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF form img {
  display: block;
  width: 10%;
  margin: 30px auto;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF h1,
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF h2,
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF h3,
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF p {
  text-align: center;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF h1 {
  margin: 15px 0 0;
  font-size: 25px;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF .UserProfile_checkbox__zCWeh {
  margin: 0;
}
.UserProfile_user-profile__AZbUE .UserProfile_content__dLyUF .UserProfile_checkbox__zCWeh a:visited {
  color: #2196f3;
}

@media only screen and (max-width: 993px) {
  .UserProfile_user-profile__AZbUE {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UserProfile/UserProfile.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AADJ;AAGI;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,cAAA;AADR;AAGQ;EACI,SAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AADZ;AAGY;EACI,SAAA;EACA,gBAAA;AADhB;AAIY;EACI,cAAA;EACA,UAAA;EACA,iBAAA;AAFhB;AAMQ;;;;EAII,kBAAA;AAJZ;AAOQ;EACI,gBAAA;EACA,eAAA;AALZ;AAQQ;EACI,SAAA;AANZ;AAQY;EACI,cAAA;AANhB;;AAYA;EACI;IACI,YAAA;EATN;AACF","sourcesContent":["@import '../../assets/scss/variables';\n\n.user-profile {\n    display: flex;\n    width: 100%;\n    height: 100%;\n\n    .content {\n        display: flex;\n        flex-direction: column;\n        width: 100%;\n        overflow: auto;\n\n        form {\n            flex: 1 1;\n            margin: 1rem;\n            padding: 1.25rem;\n            overflow-y: auto;\n            background: #fff;\n            border: 1px solid #d3d3d3;\n            border-radius: 4px;\n\n            h3 {\n                margin: 0;\n                text-align: left;\n            }\n\n            img {\n                display: block;\n                width: 10%;\n                margin: 30px auto;\n            }\n        }\n\n        h1,\n        h2,\n        h3,\n        p {\n            text-align: center;\n        }\n\n        h1 {\n            margin: 15px 0 0;\n            font-size: 25px;\n        }\n\n        .checkbox {\n            margin: 0;\n\n            a:visited {\n                color: #2196f3;\n            }\n        }\n    }\n}\n\n@media only screen and (max-width: $width-md) {\n    .user-profile {\n        width: 100vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-profile": `UserProfile_user-profile__AZbUE`,
	"content": `UserProfile_content__dLyUF`,
	"checkbox": `UserProfile_checkbox__zCWeh`
};
export default ___CSS_LOADER_EXPORT___;
