export enum responseTypes {
    JSON = 'json',
    BLOB = 'blob',
}
export const requestMethod = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

export const LINKS = {
    LANDING: '/',
    HOME: '/home',
    LOGIN: '/login',
    REPORTING: '/reporting',
    CREATE_ORDERS: '/create-orders',
    RESET_PASSWORD: '/reset-password',
    CHANGE_PASSWORD: '/change-password',
    REGISTRATION: '/registration',
    USER_PROFILE: '/user-profile',
    API_CONFIGURATION: '/api-configuration',
    USER_AGREEMENT: '/user-agreement',
    POLICY_PRIVACY: '/privacy-policy',
};
export const defaultErrorMessage = 'Something was wrong!';

export const BASE_URL = 'https://66682fcbf53957909ff6f2be.mockapi.io/api/v1';

//ToDo remove after get real back end
export const getPrimeToMockApi = (data: {from: number; size: number}) => {
    return data.from / data.size + 1;
};
