// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Snackbar_error__lj9UL {
  color: #ef4444 !important;
  background: #ffe7e6 !important;
  border: solid #ef4444 !important;
  border-width: 0 0 0 6px !important;
}

.Snackbar_success__6L\\+iG {
  color: #22c55e !important;
  background: #e4f8f0 !important;
  border: solid #22c55e !important;
  border-width: 0 0 0 6px !important;
}
.Snackbar_success__6L\\+iG button {
  color: #22c55e !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Snackbar/Snackbar.module.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,8BAAA;EACA,gCAAA;EACA,kCAAA;AADJ;;AAIA;EACI,yBAAA;EACA,8BAAA;EACA,gCAAA;EACA,kCAAA;AADJ;AAGI;EACI,yBAAA;AADR","sourcesContent":["@import '../../assets/scss/variables';\n\n.error {\n    color: $danger !important;\n    background: $danger-bg !important;\n    border: solid $danger !important;\n    border-width: 0 0 0 6px !important;\n}\n\n.success {\n    color: $success !important;\n    background: $success-bg !important;\n    border: solid $success !important;\n    border-width: 0 0 0 6px !important;\n\n    button {\n        color: $success !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `Snackbar_error__lj9UL`,
	"success": `Snackbar_success__6L+iG`
};
export default ___CSS_LOADER_EXPORT___;
