import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';

import {LINKS} from '../../../globalConstants';
import {resetPasswordFormSchema} from './resetPasswordValidationForm';

import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(LINKS.LOGIN);
    };

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(resetPasswordFormSchema),
    });

    const submitHandler = (data) => {
        if (data) {
            navigate(LINKS.LOGIN, {state: {reset: true}});
        }
    };

    const onSubmit = (data) => {
        submitHandler(data);
    };

    return (
        <div className={styles['reset-password']}>
            {isLoading && (
                <div className={styles.spinner}>
                    <ProgressSpinner />
                </div>
            )}
            <div className={styles['form-wrapper']}>
                <div className={styles['header']}>
                    <Button icon="pi pi-arrow-left" onClick={goBack} rounded text raised />
                    <div className={styles['header-text']}>Reset your password</div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.info}>
                        Please enter your email address. We will send you an email to reset your password.
                    </div>
                    <div className={styles['form-group']}>
                        <InputText
                            type="email"
                            className={classNames(styles['form-control'], errors.email && 'input-error-border')}
                            id="email"
                            {...register('email')}
                            placeholder="yours@example.com"></InputText>
                        {errors.email?.message && <span className="error-message">{errors.email?.message}</span>}
                    </div>
                    <button type="submit" className={styles.submit}>
                        SEND EMAIL
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
