import React, {FC} from 'react';
import {MultiSelect} from 'primereact/multiselect';
import {SelectItemOptionsType} from 'primereact/selectitem';
import classNames from 'classnames';

import styles from './MultiSelectFilter.module.scss';

interface Item {
    name: string;
    code: string;
}
interface IProps {
    label: string;
    panelClassName?: string;
    selectedItems: Item[];
    options: SelectItemOptionsType;
    filter: boolean;
    filterName: string;
    optionLabel: string;
    setSelectedItems: (arg) => void;
}

const MultiSelectFilter: FC<IProps> = ({
    label,
    panelClassName,
    selectedItems,
    options,
    filter,
    filterName,
    optionLabel,
    setSelectedItems,
}) => {
    return (
        <div className={classNames(styles['multiselect-filter'])}>
            <div className={styles['label-text']}>{label}</div>
            <MultiSelect
                className={`p-multiselect ${styles['multiselect-input']}`}
                panelClassName={panelClassName && styles['select-all']}
                value={selectedItems}
                options={options ? options : []}
                onChange={(e) => setSelectedItems(e.value)}
                optionLabel={optionLabel}
                filter={filter}
                placeholder={filterName}
                showClear={true}
                maxSelectedLabels={2}
            />
        </div>
    );
};

export default MultiSelectFilter;
