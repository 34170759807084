import React, {useCallback, useEffect, useState} from 'react';

import {useAppDispatch} from '../../../hooks/redux';
import {fetchRevenue} from '../../../store/slices/revenue/thunks';
import {fetchOrders} from '../../../store/slices/orders/thunks';
import {fetchForecast} from '../../../store/slices/forecast/thunks';

import {initialParams} from '../constants';
import {
    fetchCategories,
    fetchPrograms,
    fetchRetailers,
    fetchProductCodes,
    fetchStoreGroups,
    fetchStoreRegions,
} from '../../../store/slices/filters/thunk';
import {fetchSellsThrough} from '../../../store/slices/sellThrough/thunks';
import {fetchRetailValue} from '../../../store/slices/retailValue/thunks';

import CreateOrdersFilters from '../Filters/CreateOrdersFilters';
import LeftSideBar from '../../Common/LeftSideBar/LeftSideBar';
import CreateOrdersMain from '../CreateOrderMain/CreateOrdersMain';

import styles from './CreateOrders.module.scss';

const CreateOrdersPage = () => {
    const [filters, setFilters] = useState(null);
    const [params, setParams] = useState(initialParams);
    //ToDo replace with selectLoading selector after get real back end
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const dispatch = useAppDispatch();

    // ToDo remove after get real back end
    const getOrders = useCallback(async () => {
        const data = {
            params: params,
            filters: filters,
        };
        setIsLoading(true);
        const res = await dispatch(fetchOrders(data));
        if (res) {
            setIsLoading(false);
        }
    }, [dispatch, params, filters]);

    const onApplyNewFilters = (
        selectedRetailer,
        selectedStoreRegion,
        selectedStoreGroup,
        selectedProgram,
        selectedCategory,
        selectedProductCode,
    ) => {
        setFilters({
            retailers: selectedRetailer && selectedRetailer.length > 0 ? selectedRetailer : null,
            storeRegions: selectedStoreRegion && selectedStoreRegion.length > 0 ? selectedStoreRegion : null,
            storeGroups: selectedStoreGroup && selectedStoreGroup.length > 0 ? selectedStoreGroup : null,
            programs: selectedProgram && selectedProgram.length > 0 ? selectedProgram : null,
            categories: selectedCategory && selectedCategory.length > 0 ? selectedCategory : null,
            productCodes: selectedProductCode && selectedProductCode.length > 0 ? selectedProductCode : null,
        });
    };

    useEffect(() => {
        dispatch(fetchRetailers({}));
        dispatch(fetchCategories({}));
        dispatch(fetchStoreRegions({}));
        dispatch(fetchStoreGroups({}));
        dispatch(fetchPrograms({}));
        dispatch(fetchProductCodes({}));
    }, [dispatch]);

    useEffect(() => {
        getOrders();
    }, [dispatch, getOrders]);

    useEffect(() => {
        if (selectedOrder) {
            dispatch(fetchForecast(selectedOrder.city));
            dispatch(fetchRevenue(selectedOrder.city));
            dispatch(fetchSellsThrough(selectedOrder.city));
            dispatch(fetchRetailValue(selectedOrder.city));
        }
    }, [dispatch, selectedOrder]);

    return (
        <div className={styles['create-orders-page']}>
            <LeftSideBar>
                <CreateOrdersFilters applyNewFilters={onApplyNewFilters} />
            </LeftSideBar>
            <CreateOrdersMain
                params={params}
                setParams={setParams}
                isLoading={isLoading}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                getOrders={getOrders}
            />
        </div>
    );
};

export default CreateOrdersPage;
