import React, {FC} from 'react';
import {Button} from 'primereact/button';
import {chartTypes, sellChartOptions} from '../constants';
import {Chart} from 'react-chartjs-2';
import {setSellsThroughBarData, setSellsThroughLineData} from '../utils';
import {useSelector} from 'react-redux';
import {selectSellsThrough} from '../../../store/slices/sellThrough/selectors';

import styles from './SellThroughInfo.module.scss';

interface IProps {
    chartType: string;
    setChartType: (argy) => void;
}

const SellThroughInfo: FC<IProps> = ({chartType, setChartType}) => {
    const sells = useSelector(selectSellsThrough);
    return (
        <div className={styles.sell}>
            {chartType === 'line' ? (
                <Button icon="pi pi-chart-bar text" outlined aria-label="Filter" onClick={() => setChartType('bar')} />
            ) : (
                <Button
                    icon="pi pi-chart-line text"
                    outlined
                    aria-label="Filter"
                    onClick={() => setChartType('line')}
                />
            )}
            {chartType === chartTypes.LINE ? (
                <Chart type="line" data={setSellsThroughLineData(sells)} options={sellChartOptions} />
            ) : (
                <Chart type="bar" data={setSellsThroughBarData(sells)} options={sellChartOptions} />
            )}
        </div>
    );
};

export default SellThroughInfo;
