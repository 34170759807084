import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {BreadCrumb as PrimeBreadCrumb} from 'primereact/breadcrumb';
import {IBreadcrumb} from '../../../globalTypes';

import {LINKS} from '../../../globalConstants';

import styles from './BreadCrumb.module.scss';

interface IProps {
    items: IBreadcrumb[];
}

const BreadCrumb: FC<IProps> = ({items}) => {
    const navigate = useNavigate();
    const home = {
        icon: 'pi pi-home',
        command: () => {
            navigate(LINKS.HOME);
        },
    };

    return (
        <div className={styles['bread-crumb']}>
            <PrimeBreadCrumb model={items} home={home} />
        </div>
    );
};

export default BreadCrumb;
