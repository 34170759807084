const urls = {
    HOME: '/',
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    REVENUE: 'revenue',
    ORDERS: 'orders',
    FORECAST: 'forecast',
    PROGRAMS: 'programs-lis',
    CATEGORIES: 'categories-list',
    RETAILERS: 'retailer-list',
    STORE_REGIONS: 'store-regions-list',
    STORE_GROUPS: 'store-groups-list',
    SKUSES: 'skuses-list',
};

export default urls;
