import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {sellThroughNames} from './constants';
import {getSellsThrough} from '../../../api/sellThrough/sellThrough';

export const fetchSellsThrough = createAsyncThunk(
    `${slicesNames.sellThrough}/${sellThroughNames.fetchSellThrough}`,
    async (data: string, {rejectWithValue}) => {
        try {
            return getSellsThrough(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
