// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SellThroughInfo_sell__j\\+rNy {
  position: relative;
  width: 100%;
  height: 55%;
  max-height: 100px;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 4px;
}
.SellThroughInfo_sell__j\\+rNy button {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 25px;
  padding: 0;
  color: black;
  border: none;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateOrders/SellTroughInfo/SellThroughInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AACR","sourcesContent":[".sell {\n    position: relative;\n    width: 100%;\n    height: 55%;\n    max-height: 100px;\n    margin-top: 10px;\n    border: 1px solid black;\n    border-radius: 4px;\n\n    button {\n        position: absolute;\n        top: 5px;\n        left: 10px;\n        width: 25px;\n        padding: 0;\n        color: black;\n        border: none;\n        box-shadow: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sell": `SellThroughInfo_sell__j+rNy`
};
export default ___CSS_LOADER_EXPORT___;
