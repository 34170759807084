import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button} from 'primereact/button';
import classNames from 'classnames';
import {SnackbarKey, useSnackbar, VariantType, CloseReason} from 'notistack';

import {hideMessage, removeMessage} from '../../store/slices/snackbar/slice';
import {selectMessages} from '../../store/slices/snackbar/selectors';
import {useAppDispatch} from '../../hooks/redux';

import styles from './Snackbar.module.scss';

const Snackbar: React.FC = () => {
    const [displayed, setDisplayed] = useState<number[]>([]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const messages = useSelector(selectMessages);
    const dispatch = useAppDispatch();

    const storeDisplayed = useCallback((id: number) => setDisplayed((prev) => [...prev, id]), []);

    const removeDisplayed = useCallback((id: number) => setDisplayed((prev) => prev.filter((key) => id !== key)), []);
    const action = useCallback(
        (snackbarId: SnackbarKey) => (
            <Button
                icon="pi pi-times"
                rounded
                outlined
                severity="danger"
                aria-label="Cancel"
                onClick={() => {
                    closeSnackbar(snackbarId);
                }}
            />
        ),
        [closeSnackbar],
    );

    useEffect(() => {
        messages.forEach((item) => {
            if (displayed.includes(item.id)) return;

            enqueueSnackbar(item.message, {
                className: classNames(item.severity === 'error' ? styles.error : styles.success),
                preventDuplicate: true,
                key: item.id,
                variant: item.severity as VariantType,
                onClose: (event: React.SyntheticEvent, reason: CloseReason, key: SnackbarKey) => {
                    if (reason === 'timeout' || reason === 'maxsnack') {
                        dispatch(hideMessage({id: key}));
                    }
                },
                onExited: (event: HTMLElement, myKey: SnackbarKey) => {
                    dispatch(removeMessage(myKey));
                    removeDisplayed(Number(myKey));
                },
                action,
            });

            storeDisplayed(item.id);
        });
    }, [messages, dispatch, enqueueSnackbar, closeSnackbar, displayed, storeDisplayed, removeDisplayed, action]);

    return null;
};

export default Snackbar;
