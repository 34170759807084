import React, {FC, useRef} from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';

import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import classNames from 'classnames';

import {setLogIn} from '../../store/slices/logIn/logInSlice';
import {selectLogIn} from '../../store/slices/logIn/selectors';
import {useAppDispatch} from '../../hooks/redux';
import {LINKS} from '../../globalConstants';
import avatarPlaceholder from '../../assets/img/avatar_placeholder.png';
import {IBreadcrumb} from '../../globalTypes';

import BreadCrumb from '../Common/BreadCrumb/BreadCrumb';

import styles from './Navigation.module.scss';

interface IProps {
    items?: IBreadcrumb[];
    showBreadcrumbs: boolean;
}

const Navigation: FC<IProps> = ({items, showBreadcrumbs}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLogged = useSelector(selectLogIn);

    const menu = useRef(null);

    const logOutHandler = async () => {
        dispatch(setLogIn({isLogged: false}));
        localStorage.removeItem('auth-token');
        navigate(LINKS.LANDING);
    };

    const menuItems = [
        {
            label: 'User Profile',
            icon: 'pi pi-user',
            command: () => {
                navigate(LINKS.USER_PROFILE);
            },
        },
        {
            label: 'API Configuration',
            icon: 'pi pi-cog',
            command: () => {
                navigate(LINKS.API_CONFIGURATION);
            },
        },
        {
            label: 'Logout',
            icon: 'pi pi-power-off',
            command: () => {
                logOutHandler();
            },
        },
    ];

    const links = (
        <div className={styles.navigation}>
            <div className={styles.left}>{showBreadcrumbs && items && <BreadCrumb items={items} />}</div>
            <div className={styles.right}>
                <Menu model={menuItems} popup ref={menu} id="profile_popup_menu" />
                {isLogged ? (
                    <Button
                        className={classNames(styles.navLink, styles['auth-button'])}
                        onClick={(event) => menu.current.toggle(event)}>
                        <img src={avatarPlaceholder} className={styles.profileImage} alt="avatar" />
                        <span className={styles.profileName}>userName</span>
                    </Button>
                ) : (
                    <>
                        <Button onClick={() => navigate(LINKS.REGISTRATION)}>Registration</Button>
                        <NavLink to={LINKS.LOGIN} className={styles['nav-link']}>
                            Log In
                        </NavLink>
                    </>
                )}
            </div>
        </div>
    );

    return <div className={styles.navigation}>{links}</div>;
};

export default Navigation;
