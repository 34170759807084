// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchInput_search__hTHNX {
  width: 170px;
  height: 100%;
  padding: 2px 25px 2px 2px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AACJ","sourcesContent":[".search {\n    width: 170px;\n    height: 100%;\n    padding: 2px 25px 2px 2px;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `SearchInput_search__hTHNX`
};
export default ___CSS_LOADER_EXPORT___;
