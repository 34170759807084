import type {Middleware} from 'redux';

import {IActionWithError, showError, showSuccess} from './slice';
import {isRejectedAction} from '../createGenericSlice';
import {fetchLogOut} from '../logIn/thunks';
import {hideErrorList, showSuccessList} from './constants';
import {getErrorMessage, getPopUpMessage} from '../../../globalUtils';

export const snackbarMiddleware: Middleware<string> =
    ({dispatch}: any) =>
    (next) =>
    (action: any) => {
        const id = Date.now();
        let sessionExpiredCount = 0;

        if (action?.meta?.requestStatus === 'fulfilled') {
            const type = action.type.split('/')[1];
            showSuccessList.includes(type) && dispatch(showSuccess({id, message: getPopUpMessage(type)}));
            return next(action);
        }

        if (action?.failNotification) {
            dispatch(showError({id, message: action?.message}));
            return next(action);
        }

        if (action?.error && isRejectedAction(action)) {
            const {payload} = action as IActionWithError;
            if (payload.message.response.data.error?.code === 'SESSION_EXPIRED') {
                dispatch(fetchLogOut());
                sessionExpiredCount++;
            }
            !hideErrorList.includes(action.type) &&
                !sessionExpiredCount &&
                dispatch(showError({id, message: getErrorMessage(payload.message.response.data)}));
            return next(action);
        }

        return next(action);
    };
