import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {retailValueNames} from './constants';
import {getSellsThrough} from '../../../api/sellThrough/sellThrough';
import {getRetailValue} from '../../../api/retailValue/retailValue';

export const fetchRetailValue = createAsyncThunk(
    `${slicesNames.retailValues}/${retailValueNames.fetchRetailValue}`,
    async (data: string, {rejectWithValue}) => {
        try {
            return getRetailValue(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
