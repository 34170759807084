import {defaultErrorMessage} from './globalConstants';
import moment from 'moment/moment';

export const getPopUpMessage = (str: string) => {
    switch (str) {
        default:
            return 'Success!';
    }
};

export const getDateForBackEnd = (date: Date) => {
    return moment(date).format('YYYY-MM-DD');
};
export const getDateForUI = (date: Date) => {
    return moment(date).format('MM/DD/YYYY');
};

export const getErrorMessage = (value) => {
    if (value.errors) {
        return value.errors[0].message ? value.errors[0].message : defaultErrorMessage;
    } else if (value.error) {
        return value.error.message ? value.error.message : defaultErrorMessage;
    } else return defaultErrorMessage;
};

export const getValidCellValue = (val) => {
    if (val.length > 1 && val.startsWith('0')) {
        return val.substring(1);
    } else if (!val.length) {
        return 0;
    } else return val;
};

export const unloadCallback = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
        e.returnValue = '';
    }
    return '';
};
