import React, {useState} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {ProgressSpinner} from 'primereact/progressspinner';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';

import {setLogIn} from '../../../store/slices/logIn/logInSlice';
import {LINKS} from '../../../globalConstants';
import {loginFormSchema} from './loginValidationForm';
import {useAppDispatch} from '../../../hooks/redux';

import styles from './Login.module.scss';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(loginFormSchema),
    });

    const submitHandler = (data) => {
        if (data) {
            dispatch(setLogIn({isLogged: true}));
            localStorage.setItem('auth-token', 'authtoken');
            navigate(LINKS.HOME);
        }
    };

    const onSubmit = (data) => {
        submitHandler(data);
    };

    return (
        <div className={styles.login}>
            {isLoading && (
                <div className={styles.spinner}>
                    <ProgressSpinner />
                </div>
            )}
            <div className={styles['login-box']}>
                <div className={styles['login-header']}>
                    <div className={styles['header-text']}>Replenishment</div>
                </div>
                {errors.email && <div className={styles['error-banner']}>Wrong email or password.</div>}
                {location?.state?.reset && (
                    <div className={styles['info-banner']}>We have just sent you an email to reset your password.</div>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles['form-group']}>
                        <InputText
                            type="email"
                            className={classNames(styles['form-control'], errors.email && 'input-error-border')}
                            id="email"
                            {...register('email')}
                            placeholder="yours@example.com"></InputText>
                        {errors.email?.message && <span className="error-message">{errors.email?.message}</span>}
                    </div>
                    <div className={styles['form-group']}>
                        <Controller
                            name="password"
                            control={control}
                            render={({field}) => (
                                <Password
                                    type="password"
                                    {...field}
                                    className={classNames('password-field', errors.password && 'input-error-border')}
                                    placeholder="your password"
                                    feedback={false}
                                    toggleMask
                                />
                            )}
                        />
                        {errors.password?.message && <span className="error-message">{errors.password?.message}</span>}
                    </div>
                    <div className={styles['forgot-password']}>
                        <NavLink to={LINKS.RESET_PASSWORD} className={styles['nav-link']}>
                            Do not remember your password?
                        </NavLink>
                    </div>
                    <button type="submit" id="btn-login" className={styles['login-btn']}>
                        LOG IN
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
