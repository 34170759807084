import React from 'react';

import LeftSideBar from '../Common/LeftSideBar/LeftSideBar';
import Navigation from '../Navigation/Navigation';

import styles from './Reporting.module.scss';

const Reporting = () => {
    const breadCrumbItems = [{label: 'Reporting'}];
    return (
        <div className={styles['reporting-page']}>
            <LeftSideBar />
            <div className={styles['content']}>
                <Navigation items={breadCrumbItems} showBreadcrumbs={true} />
                <h2>Reporting</h2>
            </div>
        </div>
    );
};

export default Reporting;
