import React from 'react';

const UserAgreement = () => {
    return (
        <div>
            <h1>I agree!</h1>
        </div>
    );
};

export default UserAgreement;
