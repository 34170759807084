import {AsyncThunk} from '@reduxjs/toolkit';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export interface ILoadingState {
    isLoading: {[key: string]: boolean};
    error: {[key: string]: string | null};
}

export enum StatusesTypes {
    loading = 'loading',
    finished = 'finished',
    error = 'error',
}

export interface ErrorState {
    error: {
        code: string;
        message: string;
    };
    payload?: {
        status: number;
        statusText: string;
        data: Record<string, unknown>;
    };
    requestId: string;
}

export enum ISlicesNames {
    snackbar = 'snackbar',
    showCartSidebar = 'showCartSidebar',
}

export type SliceNames = ISlicesNames;

export interface IUrlParams {
    date: string;
    plants: string;
    categories: string;
    criteria: string;
}
export interface IUrlParamsState {
    urlParams: IUrlParams;
}

export interface IChartOptions {
    plugins?: Record<string, unknown>;
    scales?: Record<string, unknown>;
}

export interface IDataSet {
    data: number[];
    borderColor: string;
    backgroundColor: string;
}
export interface IChartData {
    labels: string[];
    datasets: IDataSet[];
}
