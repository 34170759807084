import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Divider} from 'primereact/divider';
import {InputMask} from 'primereact/inputmask';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import classNames from 'classnames';

import {registrationFormSchema} from './registrationFormValidation';
import successIcon from '../../../assets/img/success_icon.png';

import styles from './RegisrationForm.module.scss';

const RegistrationForm = () => {
    const [isConfirmed, setIsConfirmed] = useState(false);

    const {register, handleSubmit, formState, getValues} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(registrationFormSchema),
    });

    const submitHandler = (data) => {
        setIsConfirmed(true);
    };

    const onSubmit = (data) => {
        submitHandler(data);
    };

    return (
        <div className={styles['registration-form']}>
            <h1>Registration</h1>
            <Divider />
            {isConfirmed ? (
                <form className="p-shadow-24 p-jc-center p-as-center">
                    <img alt="success_icon" src={successIcon} />
                    <h3>
                        Email Confirmation sent to
                        <br />
                        {getValues().email}
                    </h3>
                    <p>Follow the instructions in the email to finish your registration.</p>
                </form>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-fluid">
                        <h2>Welcome to Replenishment</h2>
                        <p>
                            Start here by creating an account. <br /> No credit card required.
                        </p>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="firstName" className="col-12 lg:col-3 font-bold">
                                First Name
                            </label>
                            <div className="col-12 lg:col-9">
                                <InputText
                                    id="firstName"
                                    type="text"
                                    {...register('firstName')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.firstName && 'input-error-border',
                                    )}
                                />
                                {formState.errors.firstName?.message && (
                                    <span className="error-message">{formState.errors.firstName?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="lastName" className="col-12 lg:col-3 font-bold">
                                Last Name
                            </label>
                            <div className="col-12 lg:col-9">
                                <InputText
                                    id="lastName"
                                    type="text"
                                    {...register('lastName')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.firstName && 'input-error-border',
                                    )}
                                />
                                {formState.errors.lastName?.message && (
                                    <span className="error-message">{formState.errors.lastName?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="email" className="col-12 lg:col-3 font-bold">
                                Email
                            </label>
                            <div className="col-12 lg:col-9">
                                <InputText
                                    id="email"
                                    type="email"
                                    {...register('email')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.email && 'input-error-border',
                                    )}
                                />
                                {formState.errors.email?.message && (
                                    <span className="error-message">{formState.errors.email?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="phoneNumber" className="col-12 lg:col-3 font-bold">
                                Phone Number
                            </label>
                            <div className="col-12 lg:col-9">
                                <InputMask
                                    id="phoneNumber"
                                    mask="(999) 999-9999"
                                    placeholder="(999) 999-9999"
                                    {...register('phoneNumber')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.email && 'input-error-border',
                                    )}
                                />
                                {formState.errors.phoneNumber?.message && (
                                    <span className="error-message">{formState.errors.phoneNumber?.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="companyName" className="col-12 lg:col-3 font-bold">
                                Company Name
                            </label>
                            <div className="col-12 lg:col-9">
                                <InputText
                                    id="companyName"
                                    type="text"
                                    {...register('companyName')}
                                    className={classNames(
                                        styles['form-control'],
                                        formState.errors.companyName && 'input-error-border',
                                    )}
                                />
                                {formState.errors.companyName?.message && (
                                    <span className="error-message">{formState.errors.companyName?.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="grid col-12 justify-content-between p-r-0"
                        style={{marginLeft: '0', padding: '30px 0 10px'}}>
                        <div className="text-sm">
                            By signing up, you agree to our{' '}
                            <Link style={{textDecoration: 'inherit'}} to="/user-agreement" target="_blank">
                                User Agreement
                            </Link>{' '}
                            and{' '}
                            <Link style={{textDecoration: 'inherit'}} to="/privacy-policy" target="_blank">
                                Privacy Policy
                            </Link>
                        </div>
                        <Button
                            className={styles.continue}
                            label="Continue"
                            disabled={!formState.isDirty || !formState.isValid}
                            type="submit"
                        />
                    </div>
                </form>
            )}
        </div>
    );
};

export default RegistrationForm;
