import * as yup from 'yup';

export const registrationFormSchema = yup.object().shape({
    firstName: yup
        .string()
        .required('First Name should be provided.')
        .matches(
            /^[a-z](?:_?[a-z\s]+)*$/i,
            'First Name should start with a letter and can only contain letters and spaces.',
        )
        .max(10, 'Maximum length exceeded.'),
    lastName: yup
        .string()
        .required('Last Name should be provided.')
        .matches(
            /^[a-z](?:_?[a-z\s]+)*$/i,
            'Last Name should start with a letter and can only contain letters and spaces.',
        )
        .max(10, 'Maximum length exceeded.'),
    email: yup
        .string()
        .required('Email should be provided.')
        .max(100, 'Maximum length exceeded.')
        .email('Invalid email format.'),
    phoneNumber: yup.string().required('Phone Number should be provided.').max(15, 'Maximum length exceeded.'),
    companyName: yup
        .string()
        .required('Company Name should be provided.')
        .matches(/^(?!\s).*$/i, 'Company Name can not start with space.')
        .max(10, 'Maximum length exceeded.'),
});
