import {sendRequest, urls} from '../../../src/apiRequestService';
import {requestMethod} from '../../globalConstants';
import {weather} from '../../components/CreateOrders/constants';

export const getForecast = (data: string) => {
    //ToDo uncomment after get real back end
    // return sendRequest(urls.FORECAST, requestMethod.POST, {
    //     zip_index: data.zip_index,
    // });

    //ToDo remove after get real back end
    return {
        data: weather.find((item) => item.zipcode === data),
    };
};
