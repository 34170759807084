// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reporting_reporting-page__K0Sft {
  display: flex;
  width: 100%;
  height: 100%;
}
.Reporting_reporting-page__K0Sft .Reporting_content__3ONCS {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Reporting/Reporting.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,SAAA;AACR","sourcesContent":[".reporting-page {\n    display: flex;\n    width: 100%;\n    height: 100%;\n\n    .content {\n        flex: 1 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reporting-page": `Reporting_reporting-page__K0Sft`,
	"content": `Reporting_content__3ONCS`
};
export default ___CSS_LOADER_EXPORT___;
