import React, {FC, useState} from 'react';
import {Button} from 'primereact/button';

import {useSelector} from 'react-redux';
import {
    selectCategories,
    selectPrograms,
    selectRetailers,
    selectSkuses,
    selectStoreGroups,
    selectStoreRegions,
} from '../../../store/slices/filters/selectors';

import MultiSelectFilter from '../../Common/Filters/MultiSelectFilter/MultiSelectFilter';

import styles from './CreateOrdersFilters.module.scss';

interface IProps {
    applyNewFilters: any;
}

const CreateOrdersFilters: FC<IProps> = ({applyNewFilters}) => {
    //ToDo rebuild filters with formik/react-hook-form
    const [selectedRetailer, setSelectedRetailer] = useState(null);
    const [selectedStoreGroup, setSetSelectedStoreGroup] = useState(null);
    const [selectedStoreRegion, setSetSelectedStoreRegion] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedProductCode, setSelectedProductCode] = useState(null);

    const retailers = useSelector(selectRetailers);
    const storeGroups = useSelector(selectStoreGroups);
    const storeRegions = useSelector(selectStoreRegions);
    const programs = useSelector(selectPrograms);
    const categories = useSelector(selectCategories);
    const productCodes = useSelector(selectSkuses);

    const onResetFilters = () => {
        setSelectedProductCode(null);
        setSelectedRetailer(null);
        setSetSelectedStoreGroup(null);
        setSetSelectedStoreRegion(null);
        setSelectedProgram(null);
        setSelectedCategory(null);
        applyNewFilters(null, null, null, null, null, null);
    };

    const onApplyFilters = () => {
        return applyNewFilters(
            selectedRetailer,
            selectedStoreRegion,
            selectedStoreGroup,
            selectedProgram,
            selectedCategory,
            selectedProductCode,
        );
    };

    return (
        <div className={styles['create-orders-filters']}>
            <div className={styles.filters}>
                Filters
                <div className={styles['filter-controls']}>
                    <Button label="Apply" raised onClick={onApplyFilters} />
                    <Button label="Load" raised />
                    <Button label="Save" raised />
                    <Button label="Clear" raised onClick={onResetFilters} />
                </div>
                <div className={styles['filter-inputs']}>
                    <MultiSelectFilter
                        label="Retailer"
                        selectedItems={selectedRetailer}
                        options={retailers}
                        filter={false}
                        filterName="Select Retailer"
                        optionLabel="name"
                        setSelectedItems={setSelectedRetailer}
                    />
                    <MultiSelectFilter
                        label="Store Region"
                        selectedItems={selectedStoreRegion}
                        options={storeRegions}
                        filter={false}
                        filterName="Select Store Region"
                        optionLabel="name"
                        setSelectedItems={setSetSelectedStoreRegion}
                    />
                    <MultiSelectFilter
                        label="Store Groups"
                        selectedItems={selectedStoreGroup}
                        options={storeGroups}
                        filter={false}
                        filterName="Select Store Groups"
                        optionLabel="name"
                        setSelectedItems={setSetSelectedStoreGroup}
                    />
                    <MultiSelectFilter
                        label="Programs"
                        selectedItems={selectedProgram}
                        options={programs}
                        filter={false}
                        filterName="Select Programs"
                        optionLabel="name"
                        setSelectedItems={setSelectedProgram}
                    />
                    <MultiSelectFilter
                        label="Categories"
                        selectedItems={selectedCategory}
                        options={categories}
                        filter={false}
                        filterName="Select Categories"
                        optionLabel="name"
                        setSelectedItems={setSelectedCategory}
                    />
                    <MultiSelectFilter
                        label="Product Codes"
                        selectedItems={selectedProductCode}
                        options={productCodes}
                        filter={false}
                        filterName="Select Product Code"
                        optionLabel="name"
                        setSelectedItems={setSelectedProductCode}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateOrdersFilters;
