// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuDivider_menu-divider__L99NE {
  margin: 15px 0;
  border-top: 1px solid #d0cecc;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/MenuDivider/MenuDivider.module.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,6BAAA;AADJ","sourcesContent":["@import '../../../assets/scss/variables';\n\n.menu-divider {\n    margin: 15px 0;\n    border-top: 1px solid $grey-light;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-divider": `MenuDivider_menu-divider__L99NE`
};
export default ___CSS_LOADER_EXPORT___;
