import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';
import {
    fetchRetailers,
    fetchCategories,
    fetchPrograms,
    fetchProductCodes,
    fetchStoreGroups,
    fetchStoreRegions,
} from './thunk';

const initialState = {
    retailers: [],
    categories: [],
    programs: [],
    storeGroups: [],
    storeRegions: [],
    skuses: [],
};

export const filtersSlice = createGenericSlice({
    name: slicesNames.filters,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRetailers.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.retailers = payload;
            }
        });
        builder.addCase(fetchCategories.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.categories = payload;
            }
        });
        builder.addCase(fetchPrograms.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.programs = payload;
            }
        });
        builder.addCase(fetchProductCodes.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.skuses = payload;
            }
        });
        builder.addCase(fetchStoreGroups.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.storeGroups = payload;
            }
        });
        builder.addCase(fetchStoreRegions.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.storeRegions = payload;
            }
        });
    },
});
export const {resetSlice} = filtersSlice.actions;
