import React, {FC} from 'react';

import styles from './WeatherCard.module.scss';

interface IProps {
    temperature: number;
    precipitation: number;
    weather: string;
}

const WeatherCard: FC<IProps> = ({temperature, precipitation, weather}) => {
    return (
        <div className={styles.card}>
            <span className={styles[weather]} />
            <div className={styles['card-info']}>
                <span>{temperature}</span>
                <span>{`${precipitation}%`}</span>
            </div>
        </div>
    );
};

export default WeatherCard;
