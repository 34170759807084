// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Charts_charts__fvSXc {
  display: flex;
  gap: 10px;
  width: 100%;
  aspect-ratio: 7/1;
}
.Charts_charts__fvSXc .Charts_center__3B0VU {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  height: 100%;
}
.Charts_charts__fvSXc .Charts_center__3B0VU .Charts_info__eGUbx {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 45%;
}
.Charts_charts__fvSXc .Charts_center__3B0VU .Charts_info__eGUbx .Charts_info-card__T4-d7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  aspect-ratio: 1.5/1;
  border: 1px solid black;
  border-radius: 4px;
}
.Charts_charts__fvSXc .Charts_center__3B0VU .Charts_info__eGUbx .Charts_info-card__T4-d7 p {
  margin-top: 20px;
  margin-bottom: 0;
}
.Charts_charts__fvSXc .Charts_center__3B0VU .Charts_info__eGUbx .Charts_info-card__T4-d7 .Charts_value__vBd8s {
  color: green;
  font-size: 20px;
}
.Charts_charts__fvSXc .Charts_right__RxZ-3 {
  width: 40%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateOrders/Charts/Charts.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,SAAA;EACA,WAAA;EACA,iBAAA;AADJ;AAGI;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,UAAA;EACA,YAAA;AADR;AAGQ;EACI,aAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;AADZ;AAGY;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,2BAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AADhB;AAGgB;EACI,gBAAA;EACA,gBAAA;AADpB;AAIgB;EACI,YAAA;EACA,eAAA;AAFpB;AAQI;EACI,UAAA;EACA,YAAA;AANR","sourcesContent":["@import '../../../assets/scss/variables';\n\n.charts {\n    display: flex;\n    gap: 10px;\n    width: 100%;\n    aspect-ratio: 7 / 1;\n\n    .center {\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        width: 40%;\n        height: 100%;\n\n        .info {\n            display: flex;\n            gap: 10px;\n            width: 100%;\n            height: 45%;\n\n            .info-card {\n                display: flex;\n                flex-direction: column;\n                align-items: center;\n                justify-content: flex-start;\n                height: 100%;\n                aspect-ratio: 1.5/1;\n                border: 1px solid black;\n                border-radius: 4px;\n\n                p {\n                    margin-top: 20px;\n                    margin-bottom: 0;\n                }\n\n                .value {\n                    color: green;\n                    font-size: 20px;\n                }\n            }\n        }\n    }\n\n    .right {\n        width: 40%;\n        height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charts": `Charts_charts__fvSXc`,
	"center": `Charts_center__3B0VU`,
	"info": `Charts_info__eGUbx`,
	"info-card": `Charts_info-card__T4-d7`,
	"value": `Charts_value__vBd8s`,
	"right": `Charts_right__RxZ-3`
};
export default ___CSS_LOADER_EXPORT___;
