import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {filterNames} from './constants';
import {
    getCategories,
    getPrograms,
    getRetailers,
    getProductCodes,
    getStoreGroups,
    getStoreRegions,
} from '../../../api/filters/filters';

export const fetchRetailers = createAsyncThunk(
    `${slicesNames.filters}/${filterNames.fetchRetailers}`,
    async (data: Record<string, unknown>, {rejectWithValue}) => {
        try {
            return await getRetailers(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
export const fetchStoreRegions = createAsyncThunk(
    `${slicesNames.filters}/${filterNames.fetchStoreRegions}`,
    async (data: Record<string, unknown>, {rejectWithValue}) => {
        try {
            return await getStoreRegions(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
export const fetchStoreGroups = createAsyncThunk(
    `${slicesNames.filters}/${filterNames.fetchStoreGroups}`,
    async (data: Record<string, unknown>, {rejectWithValue}) => {
        try {
            return await getStoreGroups(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
export const fetchPrograms = createAsyncThunk(
    `${slicesNames.filters}/${filterNames.fetchPrograms}`,
    async (data: Record<string, unknown>, {rejectWithValue}) => {
        try {
            return await getPrograms(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
export const fetchCategories = createAsyncThunk(
    `${slicesNames.filters}/${filterNames.fetchCategories}`,
    async (data: Record<string, unknown>, {rejectWithValue}) => {
        try {
            return await getCategories(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
export const fetchProductCodes = createAsyncThunk(
    `${slicesNames.filters}/${filterNames.fetchProductCodes}`,
    async (data: Record<string, unknown>, {rejectWithValue}) => {
        try {
            return await getProductCodes(data);
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);
