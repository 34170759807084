import React, {FC, useCallback} from 'react';
import WeatherCard from './WeatherCard/WeatherCard';

import {useSelector} from 'react-redux';
import {selectForecast} from '../../../store/slices/forecast/selectors';

import styles from './WeatherInfo.module.scss';
import moment from 'moment/moment';

const WeatherInfo: FC = () => {
    const currentForecast = useSelector(selectForecast);

    const getTable = useCallback(() => {
        const TableRow = ({years}) => {
            return years?.map((year, index) => {
                return (
                    <tr key={index}>
                        <td>{year.year}</td>
                        {year.days.map((day, index) => {
                            return (
                                <td key={index}>
                                    <WeatherCard
                                        precipitation={day.precipitation}
                                        temperature={day.temperature}
                                        weather={day.weather}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                );
            });
        };
        return (
            <table>
                <caption
                    className={
                        styles.header
                    }>{`Location: ${currentForecast?.city}, ${currentForecast?.state} ${moment().format('MM/DD')}`}</caption>
                <tbody>
                    <tr className={styles.header}>
                        <th></th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                        <th>Sun</th>
                        <th>Mon</th>
                    </tr>
                    <TableRow years={currentForecast?.years} />
                </tbody>
            </table>
        );
    }, [currentForecast]);

    return <div className={styles['weather-info']}>{getTable()}</div>;
};

export default WeatherInfo;
