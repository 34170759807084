import {sendRequest, urls} from '../../apiRequestService';
import {requestMethod} from '../../globalConstants';
import {ILogInData} from './types';

export const getLogIn = (data: ILogInData) => {
    return sendRequest(urls.LOGIN, requestMethod.POST, {
        username: data.username,
        password: data.password,
    });
};

export const getLogOut = () => {
    return sendRequest(urls.LOGOUT, requestMethod.GET, {});
};
