import {getLogIn, getLogOut} from '../../../api/logIn/login';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {logInNames} from './constants';
import {ILogInData} from '../../../api/logIn/types';

export const fetchLogin = createAsyncThunk(
    `${slicesNames.logIn}/${logInNames.fetchLogIn}`,
    async (data: ILogInData, {rejectWithValue}) => {
        try {
            const response = await getLogIn(data);
            response && data.navigate('/');
            return response;
        } catch (err) {
            return rejectWithValue({message: err});
        }
    },
);

export const fetchLogOut = createAsyncThunk(`${slicesNames.logIn}/${logInNames.fetchLogOut}`, async () => {
    try {
        return await getLogOut();
    } catch (err) {
        return err.response.data.error;
    }
});
