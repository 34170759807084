import {sendRequest, urls} from '../../../apiRequestService';
import {requestMethod} from '../../../globalConstants';
import {revenues} from '../../../components/CreateOrders/constants';

export const getRevenue = (data: string) => {
    //ToDo uncomment when I have real back end

    // return sendRequest(urls.REVENUE, requestMethod.GET, data);

    //ToDo remove when I get real back end
    return revenues.find((item) => item.zipcode === data);
};
