import {IOrdersItem} from '../../api/orders/types';
import {IBodyColumn, IColumnsState, IFilters, IHeaderColumn} from './types';
import {columnHeaderSelector} from './constants';

export const getRevenueData = (revenues) => {
    const chartPrefix = 1000;
    const orderLines = revenues.map((revenue) => revenue.orderLineAmount / chartPrefix);
    return {
        labels: revenues.map((revenue) => revenue.orderYear),
        datasets: [
            {
                data: orderLines,
                borderColor: ['#284FDA', '#687479', '#28D1DA'],
                backgroundColor: ['#284FDA', '#0DB0F6', '#28D1DA'],
            },
        ],
    };
};

export const setSellsThroughLineData = (sells) => {
    return {
        labels: Object.keys(sells.sellThrough),
        datasets: [
            {
                data: Object.values(sells.sellThrough),
                borderColor: '#999999',
                backgroundColor: '#999999',
                lineTension: 0.4,
            },
        ],
    };
};

export const setSellsThroughBarData = (sells) => {
    return {
        labels: Object.keys(sells.sellThrough),
        datasets: [
            {
                data: Object.values(sells.sellThrough),
                borderColor: ['#999999', '#b6c2c7', '#A9D3E5FF'],
                backgroundColor: ['#999999', '#b6c2c7', '#A9D3E5FF'],
            },
        ],
    };
};

export const mapOrders = (data: IOrdersItem[] = []) => {
    return data.map((order) => ({
        id: order.id,
        storeName: order.storeName,
        city: order.city,
        state: order.state,
        ship_zone: order.ship_zone,
        program: order.program,
        category: order.category,
        product_code: order.product_code,
        item_description: order.item_description,
        store_inventory_on_hand: order.inventory.on_hand,
        store_inventory_days: order.inventory.days,
        required_qty: order.required.qty,
        required_shelves: order.required.shelves,
        on_order: order.on_order,
        availability_qty: order.availability.qty,
        availability_updated_qty: order.availability.updated_qty,
        availability_updated_shelves: order.availability.updated_shelves,
        forecast_monday_date: order.forecast.monday.date,
        forecast_monday_qty: order.forecast.monday.qty,
        forecast_monday_shelves: order.forecast.monday.shelves,
        forecast_monday_carts: order.forecast.monday.carts,
    }));
};

export const getFilteredData = (filters: IFilters, resData: IOrdersItem[]) => {
    const filtersLength = Object.keys(filters).filter((x) => filters[x] != null).length;

    const retailerList = filters.retailers ? filters.retailers.map((retailer) => retailer.name) : [];
    const programList = filters.programs ? filters.programs.map((program) => program.name) : [];
    const categoryList = filters.categories ? filters.categories.map((category) => category.name) : [];
    const storeGroupList = filters.storeGroups ? filters.storeGroups.map((group) => group.name) : [];
    const storeRegionList = filters.storeRegions ? filters.storeRegions.map((region) => region.name) : [];
    const productCodeList = filters.productCodes ? filters.productCodes.map((productCode) => productCode.name) : [];

    const filteredRetailers = resData.filter((item) => retailerList.includes(item.storeName));
    const filteredCategories = resData.filter((item) => categoryList.includes(item.category));
    const filteredPrograms = resData.filter((item) => programList.includes(item.program));
    const filteredGroups = resData.filter((item) => storeGroupList.includes(item.ship_zone));
    const filteredRegions = resData.filter((item) => storeRegionList.includes(item.city));
    const filteredProductCodes = resData.filter((item) => productCodeList.includes(item.product_code));
    const combinedArray = [
        ...filteredRetailers,
        ...filteredCategories,
        ...filteredPrograms,
        ...filteredGroups,
        ...filteredRegions,
        ...filteredProductCodes,
    ];

    const countMap = {};
    combinedArray.forEach((obj) => {
        const id = obj.id;
        if (countMap[id]) {
            countMap[id]++;
        } else {
            countMap[id] = 1;
        }
    });

    const result = combinedArray.filter((obj) => countMap[obj.id] === filtersLength);
    const uniqueResult = [...new Set(result)];
    return {orders: uniqueResult, total: uniqueResult.length};
};

const getIndexToUse = (frozen: boolean, length: number, index: number) => {
    if (frozen) {
        return index >= length ? length - 1 : index;
    } else {
        return index < length ? length : index;
    }
};

export const onHeaderMove = (
    startColumn: IHeaderColumn,
    targetColumn: IHeaderColumn,
    columns: IColumnsState,
    setColumns: (arg: IColumnsState) => void,
) => {
    // change top headers order
    const frozenPartLength = columns.top.filter((el) => el.frozen).length;
    const startColumnIndex = columns.top.findIndex((el) => el.field === startColumn.field);
    const targetColumnIndex = columns.top.findIndex((el) => el.field === targetColumn.field);
    const targetColumnIndexToUse = getIndexToUse(startColumn.frozen, frozenPartLength, targetColumnIndex);
    const headerToMove = columns.top.splice(startColumnIndex, 1);
    columns.top.splice(targetColumnIndexToUse, 0, ...headerToMove);

    //change bottom headers and body headers order based on previously changed top headers
    const unFrozenTopHeaders = columns.top.filter((el) => !el.frozen);
    const frozenTopHeaders = columns.top.filter((el) => el.frozen);
    const frozenBodies = columns.body.filter((el) => el.frozen);
    const unFrozenBodies = columns.body.filter((el) => !el.frozen);
    const topUnFrozenHeadersPosition = {};
    const topFrozenHeadersPosition = {};

    unFrozenTopHeaders.forEach((el, index) => {
        topUnFrozenHeadersPosition[el.field] = index;
    });

    frozenTopHeaders.forEach((el, index) => {
        topFrozenHeadersPosition[el.field] = index;
    });

    const getFieldFromColumn = (col: IHeaderColumn | IBodyColumn, frozen: boolean) => {
        const topHeaders = frozen ? frozenTopHeaders : unFrozenTopHeaders;
        for (const header of topHeaders) {
            if (col.field.startsWith(header.field)) {
                return header;
            }
        }
        return null;
    };

    const compareColumns = (col1: IBodyColumn | IHeaderColumn, col2: IBodyColumn | IHeaderColumn, frozen: boolean) => {
        const header1 = getFieldFromColumn(col1, frozen);
        const header2 = getFieldFromColumn(col2, frozen);
        const headersPosition = frozen ? topFrozenHeadersPosition : topUnFrozenHeadersPosition;

        if (header1 && header2) {
            return headersPosition[header1.field] - headersPosition[header2.field];
        } else if (header1) {
            return -1;
        } else if (header2) {
            return 1;
        } else {
            return 0;
        }
    };

    columns.bottom.sort((a, b) => compareColumns(a, b, false));
    unFrozenBodies.sort((a, b) => compareColumns(a, b, false));
    frozenBodies.sort((a, b) => compareColumns(a, b, true));

    localStorage.setItem('visibleColumns', getVisibleNames(columns.top));

    setColumns({
        top: columns.top,
        bottom: columns.bottom,
        body: [...frozenBodies, ...unFrozenBodies],
    });
};

export const getVisibleColumns = (
    initialColumns: IColumnsState,
    columns: IColumnsState,
    visibleTopHeaders: IHeaderColumn[],
    firstLoad: boolean,
) => {
    const fieldsToKeep = visibleTopHeaders.map((el) => el.field);

    const top = columns.top.filter((el) => fieldsToKeep.includes(el.field));
    let body = columns.body.filter((el) => fieldsToKeep.some((field) => el.field.includes(field)));
    let bottom = initialColumns.bottom.filter((el) => fieldsToKeep.some((field) => el.field.includes(field)));

    if (firstLoad) {
        const bodyFields = [];
        const bottomFields = [];

        const fieldOrderMap = fieldsToKeep.reduce((acc, field, index) => {
            acc[field] = index;
            return acc;
        }, {});

        top.sort((a, b) => {
            return fieldOrderMap[a.field] - fieldOrderMap[b.field];
        });

        top.forEach((el) => {
            body.forEach((bodyEl) => {
                if (bodyEl.field.includes(el.field)) {
                    bodyFields.push(bodyEl);
                }
            });
        });

        top.forEach((el) => {
            bottom.forEach((bottomEl) => {
                if (bottomEl.field.includes(el.field)) {
                    bottomFields.push(bottomEl);
                }
            });
        });

        body = bodyFields;
        bottom = bottomFields;
    }

    if (!top.some((item) => item.field === 'storeName')) {
        top.unshift(initialColumns.top[0]);
    }
    if (!body.some((item) => item.field === 'storeName')) {
        body.unshift(initialColumns.body[0]);
    }

    if (visibleTopHeaders.length === columns.top.length || visibleTopHeaders.length >= columns.top.length) {
        const oldTopHeaderFields = columns.top.map((el) => el.field);

        const newTopHeaders = visibleTopHeaders.filter((el) => !oldTopHeaderFields.includes(el.field));
        const newTopHeaderFields = newTopHeaders.map((el) => el.field);
        const newBodies = initialColumns.body.filter((el) =>
            newTopHeaderFields.some((field) => el.field.includes(field)),
        );
        const frozenTopHeaders = top.filter((el) => el.frozen);
        const frozenBodies = body.filter((el) => el.frozen);

        newTopHeaders.forEach((newCol) => {
            newCol.frozen ? top.splice(frozenTopHeaders.length, 0, newCol) : top.push(newCol);
        });
        newBodies.forEach((newBody) => {
            newBody.frozen ? body.splice(frozenBodies.length, 0, newBody) : body.push(newBody);
        });
    }
    return {
        top: top,
        bottom: bottom,
        body: body,
    };
};

export const getVisibleNames = (visibleColumns: IHeaderColumn[]) => {
    return visibleColumns.map((column) => column.field).join(',');
};

export const convertStringToColumns = (str: string, columns: IHeaderColumn[]) => {
    const savedColumnFields = str.split(',');
    const res = columns.filter((el) => savedColumnFields.includes(el.field));
    const fieldOrderMap = savedColumnFields.reduce((acc, field, index) => {
        acc[field] = index;
        return acc;
    }, {});

    res.sort((a, b) => {
        return fieldOrderMap[a.field] - fieldOrderMap[b.field];
    });
    return res;
};

export const removeImportantRule = () => {
    const styleSheets = document.styleSheets;
    for (let i = 0; i < styleSheets.length; i++) {
        const sheet = styleSheets[i];
        const rules = sheet.cssRules;
        for (let j = 0; j < rules.length; j++) {
            const rule: any = rules[j];
            if (rule.selectorText && rule.selectorText.includes(columnHeaderSelector)) {
                rule.style.cssText = rule.style.cssText.replaceAll('!important', '');
            }
        }
    }
};
